// @flow

import { filter as ENUM_FILTER, art as ENUM_ART, artist as ENUM_ARTIST } from 'Enum';

export const STORE_KEYS: Object = {
  FILTERS: 'filters',
  PAGE: 'page',
  SORT: 'sort',
  LAYOUT: 'layout',
  ROUTED: 'routed',
  Q: 'q',
  V: 'v',
  L: 'l',
};

export const DEFAULT_STORE_STATE = {
  [ENUM_FILTER.domain.ART]: {
    [STORE_KEYS.FILTERS]: {},
    [STORE_KEYS.PAGE]: 1,
    [STORE_KEYS.SORT]: ENUM_ART.rank.RANKING_DEFAULT,
    [STORE_KEYS.LAYOUT]: ENUM_ART.list.LAYOUT_FLUID,
    [STORE_KEYS.ROUTED]: false,
    [STORE_KEYS.Q]: null,
    [STORE_KEYS.V]: null,
    [STORE_KEYS.L]: null,
  },
  [ENUM_FILTER.domain.COLLECTION]: {
    [STORE_KEYS.FILTERS]: {},
    [STORE_KEYS.PAGE]: 1,
    [STORE_KEYS.ROUTED]: false,
  },
  [ENUM_FILTER.domain.COLLECTION_ART]: {
    [STORE_KEYS.FILTERS]: {},
    [STORE_KEYS.PAGE]: 1,
    [STORE_KEYS.SORT]: ENUM_ART.rank.RANKING_DEFAULT,
    [STORE_KEYS.LAYOUT]: ENUM_ART.list.LAYOUT_FLUID,
    [STORE_KEYS.ROUTED]: false,
  },
  [ENUM_FILTER.domain.ARTIST]: {
    [STORE_KEYS.FILTERS]: {},
    [STORE_KEYS.PAGE]: 1,
    [STORE_KEYS.SORT]: ENUM_ARTIST.rank.RANKING_FEATURED,
    [STORE_KEYS.ROUTED]: false,
  },
  [ENUM_FILTER.domain.ARTIST_ART]: {
    [STORE_KEYS.FILTERS]: {},
    [STORE_KEYS.PAGE]: 1,
    [STORE_KEYS.SORT]: ENUM_ART.rank.RANKING_ARTIST,
    [STORE_KEYS.LAYOUT]: ENUM_ART.list.LAYOUT_FLUID,
    [STORE_KEYS.ROUTED]: false,
  },
  [ENUM_FILTER.domain.FAVORITES_ART]: {
    [STORE_KEYS.FILTERS]: {},
    [STORE_KEYS.PAGE]: 1,
    [STORE_KEYS.LAYOUT]: ENUM_ART.list.LAYOUT_FLUID,
    [STORE_KEYS.ROUTED]: false,
  },
  [ENUM_FILTER.domain.FAVORITES_ARTIST]: {
    [STORE_KEYS.FILTERS]: {},
    [STORE_KEYS.PAGE]: 1,
    [STORE_KEYS.SORT]: ENUM_ARTIST.rank.RANKING_ALPHA,
    [STORE_KEYS.ROUTED]: false,
  },
};
