// @flow

import isInteger from 'lodash/isInteger';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import isBoolean from 'lodash/isBoolean';
import { RiseartLogger } from 'shared_services/riseart/Logger';
import { filter as FILTER_ENUM } from 'Enum';

// filter actions
export const FILTER_ACTIONS = {
  [FILTER_ENUM.domain.ART]: { UPDATE: 'FILTER_ART_ACTION_UPDATE' },
  [FILTER_ENUM.domain.COLLECTION]: { UPDATE: 'FILTER_COLLECTION_ACTION_UPDATE' },
  [FILTER_ENUM.domain.COLLECTION_ART]: { UPDATE: 'FILTER_COLLECTION_ART_ACTION_UPDATE' },
  [FILTER_ENUM.domain.ARTIST]: { UPDATE: 'FILTER_ARTIST_ACTION_UPDATE' },
  [FILTER_ENUM.domain.ARTIST_ART]: { UPDATE: 'FILTER_ARTIST_ART_ACTION_UPDATE' },
  [FILTER_ENUM.domain.FAVORITES_ART]: { UPDATE: 'FILTER_FAVORITES_ART_ACTION_UPDATE' },
  [FILTER_ENUM.domain.FAVORITES_ARTIST]: { UPDATE: 'FILTER_FAVORITES_ARTISTS_ACTION_UPDATE' },
};

/**
 * validateActionParameters
 * @param {string} action
 * @param {Object} { filters, page, sort, layout, routed }
 */
const validateActionParameters = (action, { filters, page, sort, layout, routed }) => {
  if (filters && !isObject(filters)) {
    throw new Error(`${action} : filters must be an object`);
  }
  if (page && !isInteger(page)) {
    throw new Error(`${action} : page parameter must be an integer`);
  }
  if (sort && !isString(sort)) {
    throw new Error(`${action} : sort parameter must be a string`);
  }
  if (layout && !isString(layout)) {
    throw new Error(`${action} : layout parameter must be a string`);
  }
  if (routed && !isBoolean(routed)) {
    throw new Error(`${action} : routed parameter must be a boolean`);
  }
};

/**
 * updateFilters
 * @param {string} filterDomain
 * @returns {Function}
 */
export const updateFilters =
  (filterDomain: string): Function =>
  ({ filters, page, sort, layout, routed, q, v, l }: Object): Object => {
    try {
      validateActionParameters(FILTER_ACTIONS[filterDomain].UPDATE, {
        filters,
        page,
        sort,
        layout,
        routed,
      });

      return {
        type: FILTER_ACTIONS[filterDomain].UPDATE,
        payload: {
          filters,
          page,
          sort,
          layout,
          routed,
          q,
          v,
          l,
        },
      };
    } catch (Exception) {
      RiseartLogger.exception(Exception, {
        data: {
          scope: FILTER_ACTIONS[filterDomain].UPDATE,
          filters,
          page,
          sort,
          layout,
          routed,
          q,
          v,
          l,
        },
      });
    }
  };
