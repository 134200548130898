export const application = {
  name: 'Rise Art',
  logo: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/logo.jpg',
  acl: {
    rolesWildcard: '*',
    rolesHierarchy: { visitor: { basic: { user: { artist: { seller: {} }, curator: {} } } } },
    resourcePermissions: {
      quiz: { results: 'basic' },
      cart: { credit: 'basic', giftCard: 'user' },
      follow: 'basic',
      event: { rsvp: 'basic' },
      offer: { create: 'basic' },
      lens: 'basic',
    },
    nonRegisteredRoles: { acl: ['visitor'], visitor: ['visitor', 'subscriber'] },
  },
  i18n: {
    locales: [
      { name: 'en-GB', language: 'en', basePath: 'en', isDefault: true },
      { name: 'de-DE', language: 'de', basePath: 'de' },
      { name: 'fr-FR', language: 'fr', basePath: 'fr' },
    ],
    currency: { signs: { uk: '£', eu: '€', us: '$' }, codes: { uk: 'GBP', eu: 'EUR', us: 'USD' } },
  },
  units: {
    defaultSystem: 'metric',
    metric: {
      length: { unit: 'CENTIMETER', precision: { low: 0, high: 1 } },
      weight: { unit: 'KILOGRAM' },
    },
    imperial: {
      length: { unit: 'INCH', precision: { low: 1, high: 2 } },
      weight: { unit: 'POUND' },
    },
  },
  redirector: { maxHops: 3 },
  auth: { register: { domain: 'ra' } },
  countries: { excluded: ['A1', 'A2', 'AP', 'O1', 'EU'] },
  store: { default: 'us' },
  domain: 'riseart.local',
  host: 'www.dev.riseart.com',
  environmentLoggerScript: '/assets/scripts/environment.js',
  readWriteDelay: 2000,
};
export const payment = {
  buy: { defaultMethod: 'riseart_stripe' },
  rent: { defaultMethod: 'riseart_stripe' },
  stripe: {
    enabled: true,
    minimumPaymentAmount: 50,
    methods: { rent: ['card'] },
    confirmLeeway: 1800,
  },
  datacash: { enabled: false },
};
export const libs = {
  facebook: {
    statusConnected: 'connected',
    scope: 'email, public_profile',
    sdkVersion: 'v2.8',
    tagId: 'facebook-module',
    source: 'https://connect.facebook.net/en_US/sdk.js',
    scriptTag: 'script',
    appId: '173428642689428',
  },
  google: {
    tagId: 'google-module',
    source: 'https://accounts.google.com/gsi/client',
    scriptTag: 'script',
    jsType: 'text/javascript',
    allowPrompt: true,
    callbackName: 'googleAuthCallback',
    consent: {
      state: {
        all: {
          adStorage: true,
          adUserData: true,
          adPersonalization: true,
          analyticsStorage: true,
          functionalityStorage: true,
          personalizationStorage: true,
          securityStorage: true,
        },
        required: {
          adStorage: true,
          adUserData: false,
          adPersonalization: false,
          analyticsStorage: true,
          functionalityStorage: true,
          personalizationStorage: true,
          securityStorage: true,
        },
      },
    },
    clientId: '860827466167-4r070vis3gill74mbi9nr3bffit8dtfi.apps.googleusercontent.com',
  },
  events: {
    tagId: 'ra-events-lib',
    file: 'track.min.js',
    namespace: 'raev',
    source: 'frontend',
    host: 'https://s3-eu-west-1.amazonaws.com/dev-packages.riseart/events-lib-js',
    apiKey: '7d4af165-add7-4ed5-9878-e313c2c9e79b',
    debug: true,
  },
  stripe: { apiKey: 'pk_test_LA4g3OkSFoJuO9Ahg1jLbw86' },
  intercom: { workspaceId: 'pptzcu0t', loadDelay: 5000 },
};
export const artDirection = {
  common: {
    avatar: [
      { width: 24, height: 'AUTO' },
      { width: 48, height: 'AUTO' },
      { width: 96, height: 'AUTO' },
      { width: 192, height: 'AUTO' },
    ],
    banner: [
      { width: 450, height: 'AUTO' },
      { width: 800, height: 'AUTO' },
      { width: 900, height: 'AUTO' },
      { width: 1440, height: 'AUTO' },
      { width: 1600, height: 'AUTO' },
      { width: 2880, height: 'AUTO' },
    ],
  },
  art: {
    main: [
      { width: 140, height: 'AUTO' },
      { width: 280, height: 'AUTO' },
      { width: 350, height: 'AUTO' },
      { width: 560, height: 'AUTO' },
      { width: 700, height: 'AUTO' },
      { width: 1120, height: 'AUTO' },
    ],
    zoom: [
      { width: 700, height: 'AUTO' },
      { width: 1120, height: 'AUTO' },
      { width: 2240, height: 'AUTO' },
    ],
    fixedHeight: [
      { width: 'AUTO', height: 300 },
      { width: 'AUTO', height: 600 },
      { width: 'AUTO', height: 900 },
    ],
  },
  collection: {
    main: [
      { width: 350, height: 'AUTO' },
      { width: 450, height: 'AUTO' },
      { width: 600, height: 'AUTO' },
      { width: 700, height: 'AUTO' },
      { width: 900, height: 'AUTO' },
      { width: 1200, height: 'AUTO' },
    ],
    square: [
      { width: 300, height: 'AUTO' },
      { width: 360, height: 'AUTO' },
      { width: 600, height: 'AUTO' },
      { width: 720, height: 'AUTO' },
    ],
  },
  quizProfileRelation: {
    main: [
      { width: 270, height: 'AUTO' },
      { width: 350, height: 'AUTO' },
      { width: 540, height: 'AUTO' },
      { width: 700, height: 'AUTO' },
    ],
  },
  quizProfile: {
    main: [
      { width: 270, height: 'AUTO' },
      { width: 350, height: 'AUTO' },
      { width: 540, height: 'AUTO' },
      { width: 700, height: 'AUTO' },
    ],
  },
  quizQuestion: {
    main: [
      { width: 580, height: 'AUTO' },
      { width: 960, height: 'AUTO' },
      { width: 1160, height: 'AUTO' },
      { width: 1920, height: 'AUTO' },
    ],
  },
  cms: {
    collection: [
      { width: 210, height: 'AUTO' },
      { width: 285, height: 'AUTO' },
      { width: 375, height: 'AUTO' },
      { width: 420, height: 'AUTO' },
      { width: 570, height: 'AUTO' },
      { width: 750, height: 'AUTO' },
    ],
    editorial: [
      { width: 330, height: 'AUTO' },
      { width: 450, height: 'AUTO' },
      { width: 540, height: 'AUTO' },
      { width: 660, height: 'AUTO' },
      { width: 900, height: 'AUTO' },
      { width: 1080, height: 'AUTO' },
    ],
    imageStacker: [
      { width: 400, height: 'AUTO' },
      { width: 800, height: 'AUTO' },
      { width: 900, height: 'AUTO' },
      { width: 1600, height: 'AUTO' },
      { width: 1800, height: 'AUTO' },
    ],
    pageBanner: [{ width: 720, height: 'AUTO' }],
  },
  artist: {
    banner: [
      { width: 450, height: 'AUTO' },
      { width: 900, height: 'AUTO' },
      { width: 1100, height: 'AUTO' },
      { width: 2200, height: 'AUTO' },
    ],
    additional: [
      { width: 450, height: 'AUTO' },
      { width: 900, height: 'AUTO' },
      { width: 1600, height: 'AUTO' },
      { width: 2880, height: 'AUTO' },
    ],
    detail: [
      { width: 450, height: 'AUTO' },
      { width: 600, height: 'AUTO' },
      { width: 900, height: 'AUTO' },
      { width: 1200, height: 'AUTO' },
    ],
    feed: [
      { width: 375, height: 'AUTO' },
      { width: 750, height: 'AUTO' },
      { width: 1500, height: 'AUTO' },
    ],
  },
  event: {
    main: [
      { width: 375, height: 'AUTO' },
      { width: 550, height: 'AUTO' },
      { width: 750, height: 'AUTO' },
      { width: 1400, height: 'AUTO' },
    ],
  },
  article: {
    main: [
      { width: 250, height: 'AUTO' },
      { width: 500, height: 'AUTO' },
      { width: 1200, height: 'AUTO' },
    ],
    inline: [
      { width: 360, height: 'AUTO' },
      { width: 720, height: 'AUTO' },
      { width: 1440, height: 'AUTO' },
    ],
  },
  activity: {
    main: [
      { width: 250, height: 'AUTO' },
      { width: 500, height: 'AUTO' },
      { width: 1200, height: 'AUTO' },
    ],
  },
  inRoomBackground: {
    main: [
      { width: 140, height: 'AUTO' },
      { width: 280, height: 'AUTO' },
      { width: 350, height: 'AUTO' },
      { width: 560, height: 'AUTO' },
      { width: 700, height: 'AUTO' },
      { width: 1120, height: 'AUTO' },
    ],
    scaled: [
      { width: 560, height: 'AUTO' },
      { width: 700, height: 'AUTO' },
      { width: 1120, height: 'AUTO' },
      { width: 2240, height: 'AUTO' },
    ],
  },
};
export const components = {
  messages: {
    application: {
      service: { prefix: 'message/', position: 'top' },
      type: {
        visitorSetting: 'visitor/setting',
        cookiePersistent: 'cookie/persistent',
        cookieSession: 'cookie/session',
      },
    },
  },
  reviewWidget: {
    source: '//widget.reviews.io/carousel-inline-iframeless/dist.js?_t=2023080206',
    cssCommon: '//assets.reviews.io/css/widgets/carousel-widget.css?_t=2023080206',
    cssIcons: '//assets.reviews.io/iconfont/reviewsio-icons/style.css?_t=2023080206',
    store: 'riseart-com',
  },
  godaddySeal: {
    source:
      'https://seal.godaddy.com/getSeal?sealID=stmeuKQXMkeQogaSGp6z9OeVCGqJeBBEyPlnbqu35HgEMulnAtltshDtCpm',
    placeholderId: 'siteseal',
  },
  search: {
    timeout: 500,
    suggestions: { minChar: 3 },
    main: { timeout: 500, trackingEventTimeout: 1000, minChar: 3 },
    lens: { overlay: { delay: 2000 } },
  },
  offer: { maxAllowedDiscountPercent: 20 },
  order: { readQueryTimeout: 1000 },
  cms: {
    modules: {
      hero: { default_image: 'https://d1ee3oaj5b5ueh.cloudfront.net/2017/10/59dcad93c93c0.jpeg' },
    },
  },
  collections: {
    fallbackImages: [
      {
        type: 'main-master',
        key: 'static_fallback_collection_main-master',
        extension: 'jpg',
        height: 800,
        width: 1200,
      },
      {
        type: 'main-square',
        key: 'static_fallback_collection_main-square',
        extension: 'jpg',
        height: 720,
        width: 720,
      },
      {
        type: 'banner-large',
        key: 'static_fallback_collection_banner-large',
        extension: 'jpg',
        height: 740,
        width: 2880,
      },
      {
        type: 'banner-medium',
        key: 'static_fallback_collection_banner-medium',
        extension: 'jpg',
        height: 740,
        width: 1600,
      },
      {
        type: 'banner-small',
        key: 'static_fallback_collection_banner-small',
        extension: 'jpg',
        height: 740,
        width: 900,
      },
    ],
  },
  art: {
    fallbackImages: [
      {
        type: 'main-master',
        key: 'static_fallback_art_original-master',
        extension: 'jpg',
        height: 1120,
        width: 1120,
      },
      {
        type: 'main-square',
        key: 'static_fallback_art_original-square',
        extension: 'jpg',
        height: 1120,
        width: 1120,
      },
      {
        type: 'detail-master',
        key: 'static_fallback_art_original-master',
        extension: 'jpg',
        height: 1120,
        width: 1120,
      },
      {
        type: 'detail-square',
        key: 'static_fallback_art_original-square',
        extension: 'jpg',
        height: 1120,
        width: 1120,
      },
      {
        type: 'master',
        key: 'static_fallback_art_original-master',
        extension: 'jpg',
        height: 1120,
        width: 1120,
      },
      {
        type: 'square',
        key: 'static_fallback_art_original-square',
        extension: 'jpg',
        height: 1120,
        width: 1120,
      },
    ],
    viewInRoom: {
      backgrounds: {
        main: {
          pixelsPerCentimeter: 4.142857142857143,
          artwork: {
            width: { min: 0, max: 7870 },
            height: { min: 0, max: 4640 },
            x: 1538,
            y: 1828,
          },
          scale: {
            artwork: {
              width: { min: 0, max: 6630 },
              height: { min: 0, max: 4350 },
              x: 1525,
              y: 1790,
            },
            background: {
              key: 'static_in-room_gallery-large',
              extension: 'jpg',
              width: 2000,
              height: 2274,
            },
          },
          background: {
            key: 'static_in-room_gallery-large',
            extension: 'jpg',
            width: 3075,
            height: 3500,
          },
        },
        additional: [
          {
            pixelsPerCentimeter: 42.1,
            artwork: { width: { min: 0, max: 25 }, height: { min: 0, max: 25 }, x: 709, y: 631 },
            cropArea: { width: 1052, height: 1052, x: 709, y: 631 },
            background: {
              key: 'static_in-room_room1-large',
              extension: 'jpg',
              width: 2651,
              height: 2000,
            },
            thumbnail: {
              key: 'static_in-room_room1-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 12.5,
            artwork: { width: { min: 0, max: 20 }, height: { min: 0, max: 24 }, x: 619, y: 398 },
            cropArea: { width: 250, height: 300, x: 619, y: 398 },
            background: {
              key: 'static_in-room_room2-large',
              extension: 'jpg',
              width: 1282,
              height: 2000,
            },
            thumbnail: {
              key: 'static_in-room_room2-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 8,
            artwork: { width: { min: 20, max: 45 }, height: { min: 10, max: 55 }, x: 741, y: 423 },
            cropArea: { width: 360, height: 440, x: 741, y: 423 },
            background: {
              key: 'static_in-room_room3-large',
              extension: 'jpg',
              width: 1386,
              height: 2000,
            },
            thumbnail: {
              key: 'static_in-room_room3-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 17.3,
            artwork: { width: { min: 20, max: 45 }, height: { min: 10, max: 65 }, x: 531, y: 798 },
            cropArea: { width: 780, height: 1125, x: 531, y: 798 },
            background: {
              key: 'static_in-room_room4-large',
              extension: 'jpg',
              width: 1727,
              height: 2000,
            },
            thumbnail: {
              key: 'static_in-room_room4-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 14.4,
            artwork: { width: { min: 20, max: 80 }, height: { min: 10, max: 70 }, x: 685, y: 742 },
            cropArea: { width: 1152, height: 1010, x: 685, y: 742 },
            background: {
              key: 'static_in-room_room5-large',
              extension: 'jpg',
              width: 1933,
              height: 2000,
            },
            thumbnail: {
              key: 'static_in-room_room5-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 11.3,
            artwork: { width: { min: 21, max: 55 }, height: { min: 20, max: 55 }, x: 1096, y: 599 },
            cropArea: { width: 622, height: 622, x: 1096, y: 599 },
            background: {
              key: 'static_in-room_room6-large',
              extension: 'jpg',
              width: 2026,
              height: 2000,
            },
            thumbnail: {
              key: 'static_in-room_room6-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 9.5,
            artwork: { width: { min: 20, max: 75 }, height: { min: 40, max: 90 }, x: 980, y: 574 },
            cropArea: { width: 715, height: 855, x: 980, y: 574 },
            background: {
              key: 'static_in-room_room7-large',
              extension: 'jpg',
              width: 2000,
              height: 2000,
            },
            thumbnail: {
              key: 'static_in-room_room7-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 9.9,
            artwork: {
              width: { min: 40, max: 110 },
              height: { min: 16, max: 45 },
              x: 1479,
              y: 345,
            },
            cropArea: { width: 1090, height: 450, x: 1479, y: 345 },
            background: {
              key: 'static_in-room_room8-large',
              extension: 'jpg',
              width: 2889,
              height: 1616,
            },
            thumbnail: {
              key: 'static_in-room_room8-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 8.3,
            artwork: {
              width: { min: 20, max: 80 },
              height: { min: 45, max: 100 },
              x: 1081,
              y: 552,
            },
            cropArea: { width: 664, height: 830, x: 1081, y: 552 },
            background: {
              key: 'static_in-room_room9-large',
              extension: 'jpg',
              width: 1539,
              height: 2000,
            },
            thumbnail: {
              key: 'static_in-room_room9-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 7,
            artwork: {
              width: { min: 90, max: 237 },
              height: { min: 40, max: 130 },
              x: 1444,
              y: 578,
            },
            cropArea: { width: 1660, height: 910, x: 1444, y: 578 },
            background: {
              key: 'static_in-room_room10-large',
              extension: 'jpg',
              width: 2880,
              height: 1920,
            },
            thumbnail: {
              key: 'static_in-room_room10-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 7.6,
            artwork: { width: { min: 45, max: 95 }, height: { min: 50, max: 130 }, x: 792, y: 680 },
            cropArea: { width: 722, height: 988, x: 792, y: 680 },
            background: {
              key: 'static_in-room_room11-large',
              extension: 'jpg',
              width: 1538,
              height: 2000,
            },
            thumbnail: {
              key: 'static_in-room_room11-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 7.2,
            artwork: {
              width: { min: 60, max: 185 },
              height: { min: 60, max: 100 },
              x: 1085,
              y: 536,
            },
            cropArea: { width: 1332, height: 720, x: 1085, y: 536 },
            background: {
              key: 'static_in-room_room12-large',
              extension: 'jpg',
              width: 2315,
              height: 2000,
            },
            thumbnail: {
              key: 'static_in-room_room12-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 4.5,
            artwork: {
              width: { min: 80, max: 200 },
              height: { min: 80, max: 150 },
              x: 1486,
              y: 415,
            },
            cropArea: { width: 900, height: 675, x: 1486, y: 415 },
            background: {
              key: 'static_in-room_room13-large',
              extension: 'jpg',
              width: 2880,
              height: 1632,
            },
            thumbnail: {
              key: 'static_in-room_room13-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 4.5,
            artwork: {
              width: { min: 100, max: 200 },
              height: { min: 30, max: 80 },
              x: 1486,
              y: 551,
            },
            cropArea: { width: 900, height: 360, x: 1486, y: 551 },
            background: {
              key: 'static_in-room_room14-large',
              extension: 'jpg',
              width: 2880,
              height: 1632,
            },
            thumbnail: {
              key: 'static_in-room_room14-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 6.3,
            artwork: {
              width: { min: 60, max: 150 },
              height: { min: 60, max: 150 },
              x: 1135,
              y: 696,
            },
            cropArea: { width: 945, height: 945, x: 1135, y: 696 },
            background: {
              key: 'static_in-room_room15-large',
              extension: 'jpg',
              width: 2086,
              height: 2000,
            },
            thumbnail: {
              key: 'static_in-room_room15-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 7.3,
            artwork: {
              width: { min: 80, max: 200 },
              height: { min: 20, max: 80 },
              x: 1891,
              y: 612,
            },
            cropArea: { width: 1460, height: 584, x: 1891, y: 612 },
            background: {
              key: 'static_in-room_room16-large',
              extension: 'jpg',
              width: 2880,
              height: 1870,
            },
            thumbnail: {
              key: 'static_in-room_room16-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 4.9,
            artwork: {
              width: { min: 45, max: 170 },
              height: { min: 95, max: 250 },
              x: 1194,
              y: 1056,
            },
            cropArea: { width: 833, height: 1225, x: 1194, y: 1056 },
            background: {
              key: 'static_in-room_room17-large',
              extension: 'jpg',
              width: 2667,
              height: 2000,
            },
            thumbnail: {
              key: 'static_in-room_room17-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 5.4,
            artwork: {
              width: { min: 50, max: 160 },
              height: { min: 90, max: 140 },
              x: 1355,
              y: 696,
            },
            cropArea: { width: 864, height: 756, x: 1355, y: 696 },
            background: {
              key: 'static_in-room_room18-large',
              extension: 'jpg',
              width: 2880,
              height: 1920,
            },
            thumbnail: {
              key: 'static_in-room_room18-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 5.4,
            artwork: {
              width: { min: 50, max: 160 },
              height: { min: 140, max: 200 },
              x: 1349,
              y: 596,
            },
            cropArea: { width: 864, height: 1080, x: 1349, y: 596 },
            background: {
              key: 'static_in-room_room19-large',
              extension: 'jpg',
              width: 2880,
              height: 1920,
            },
            thumbnail: {
              key: 'static_in-room_room19-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 3.7,
            artwork: {
              width: { min: 200, max: 300 },
              height: { min: 140, max: 200 },
              x: 1441,
              y: 566,
            },
            cropArea: { width: 1110, height: 740, x: 1441, y: 566 },
            background: {
              key: 'static_in-room_room20-large',
              extension: 'jpg',
              width: 2880,
              height: 1620,
            },
            thumbnail: {
              key: 'static_in-room_room20-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 3.7,
            artwork: {
              width: { min: 200, max: 300 },
              height: { min: 81, max: 139 },
              x: 1441,
              y: 659,
            },
            cropArea: { width: 1110, height: 515, x: 1441, y: 659 },
            background: {
              key: 'static_in-room_room21-large',
              extension: 'jpg',
              width: 2880,
              height: 1620,
            },
            thumbnail: {
              key: 'static_in-room_room21-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 1.9,
            artwork: {
              width: { min: 180, max: 400 },
              height: { min: 140, max: 250 },
              x: 1457,
              y: 747,
            },
            cropArea: { width: 760, height: 475, x: 1457, y: 747 },
            background: {
              key: 'static_in-room_room22-large',
              extension: 'jpg',
              width: 2880,
              height: 1620,
            },
            thumbnail: {
              key: 'static_in-room_room22-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 1.9,
            artwork: {
              width: { min: 200, max: 300 },
              height: { min: 81, max: 139 },
              x: 1457,
              y: 831,
            },
            cropArea: { width: 570, height: 265, x: 1457, y: 831 },
            background: {
              key: 'static_in-room_room23-large',
              extension: 'jpg',
              width: 2880,
              height: 1620,
            },
            thumbnail: {
              key: 'static_in-room_room23-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
          {
            pixelsPerCentimeter: 4.2,
            artwork: {
              width: { min: 100, max: 200 },
              height: { min: 160, max: 290 },
              x: 1194,
              y: 697,
            },
            cropArea: { width: 840, height: 1218, x: 1194, y: 697 },
            background: {
              key: 'static_in-room_room24-large',
              extension: 'jpg',
              width: 2400,
              height: 2000,
            },
            thumbnail: {
              key: 'static_in-room_room24-thumbnail',
              extension: 'jpg',
              width: 350,
              height: 350,
            },
          },
        ],
      },
    },
  },
  articles: {
    fallbackImages: [
      {
        type: 'main-master',
        key: 'static_fallback_article_main-master',
        extension: 'jpg',
        height: 900,
        width: 1200,
      },
    ],
  },
  artist: {
    feed: {
      fallbackImages: [
        {
          type: 'original',
          key: 'static_fallback_artist-feed_original',
          extension: 'jpg',
          height: 1125,
          width: 1500,
        },
      ],
    },
  },
  events: {
    fallbackImages: [
      {
        type: 'master',
        key: 'static_fallback_event_master',
        extension: 'jpg',
        height: 1050,
        width: 1400,
      },
      {
        type: 'square',
        key: 'static_fallback_event_square',
        extension: 'jpg',
        height: 1050,
        width: 1050,
      },
    ],
  },
};
export const cookies = {
  token: { name: '__ratkn' },
  visitor: { name: 'RA_WWW_VISITOR', expires: 365 },
  marketing: { name: 'RA_WWW_MKTG', expires: 30 },
  marketingSession: { name: '__ramkg' },
  referrerSession: { name: '__rarfr' },
  quizHintSession: { name: '__raqzhnt' },
  blackFridayCountdownSession: { name: '__rabfc' },
  springSaleCountdownSession: { name: '__rassc' },
  artistSpotlightCountdownSession: { name: '__raasc' },
  recentArtworksWidget: { name: '__ralva' },
  applicationMessagesPersistent: { name: '__ramsgp', expires: 365 },
  applicationMessagesSession: { name: '__ramsgs' },
  productUrgentMessageSession: { name: '__rapum' },
  consent: { name: '__ra_consent', expires: 1825 },
};
export const storage = {
  recentlyViewedArtworks: { type: 'localStorage', key: '__rarva', limit: 35, timeLimitDays: 30 },
  recentArtworksWidget: {
    type: 'localStorage',
    key: '__rarva',
    limit: 21,
    timeLimitDays: 30,
    userInactivityMins: 15,
  },
};
export const ssr = {
  markupPlaceholders: {
    pageTitle: 'META_PAGE_TITLE',
    htmlLanguage: 'HTML_LANGUAGE',
    headMeta: 'HEAD_META',
    headContent: 'HEAD_CONTENT',
    loadableLinkTags: 'LOADABLE_LINK_TAGS',
    loadableStyleTags: 'LOADABLE_STYLE_TAGS',
    loadableScriptTags: 'LOADABLE_SCRIPT_TAGS',
    initialStateScripts: 'INITIAL_STATE_SCRIPTS',
    htmlContent: 'HTML_CONTENT',
  },
  htmlIDs: { pageTitle: 'ra-logo-page-title' },
};
export const navigation = {
  uri: {
    query: {
      acl: {
        canonical: { defaultBehaviour: 'deny', blacklist: ['forward', 'test'], whitelist: ['utm'] },
        hreflang: { defaultBehaviour: 'allow', blacklist: ['utml', 'test'], whitelist: ['utm'] },
      },
    },
  },
  social: {
    facebook: { label: 'facebook', link: 'http://www.facebook.com/risearts' },
    twitter: { label: 'twiter', link: 'http://www.twitter.com/riseart' },
    pinterest: { label: 'pinterest', link: 'http://pinterest.com/riseart/' },
    instagram: { label: 'instagram', link: 'http://www.instagram.com/riseart/' },
  },
};
export const file = {
  imageUpload: { maxFilesize: 10485760, acceptedFiles: { 'image/*': ['.jpeg', '.jpg', '.png'] } },
};
export const store = {
  defaultState: { notification: {}, auth: { token: null, payload: null }, user: null },
  keys: {
    application: 'STORE_application',
    notifications: 'DATA_notifications',
    favorites: 'DATA_favorites',
    cms: 'DATA_cms',
    pagePreloader: 'DATA_page_preloader',
    filterArt: 'STORE_filter_art',
    filterCollection: 'STORE_filter_collection',
    filterCollectionArt: 'STORE_filter_collection_art',
    filterArtist: 'STORE_filter_artist',
    filterArtistArt: 'STORE_filter_artist_art',
    filterFavoritesArt: 'STORE_filter_favorites_art',
    filterFavoritesArtist: 'STORE_filter_favorites_artist',
    auth: 'STORE_auth',
    me: 'STORE_me',
    marketing: 'STORE_marketing',
    gui: 'STORE_gui',
    errors: 'STORE_errors',
    notFound: 'STORE_not_found',
  },
};
export const router = [
  {
    key: 'home',
    path: ['/:lang(en)?', '/:lang(de)', '/:lang(fr)'],
    component: 'Home',
    module: 'frontend/index/index',
    breadcrumb: '',
    label: 'Home',
    cmsViewRenderSsr: true,
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.home.pageTitle' },
      metaTitle: 'meta.home.title',
      metaKeywords: 'meta.home.keywords',
      metaDescription: 'meta.home.description',
      linkCanonical: 'pathname',
      ogTitle: 'meta.home.title',
      ogDescription: 'meta.home.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/main.jpg',
      ogUrl: 'pathname',
      twitterCard: 'summary',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'artDetail',
    path: [
      '/:lang(en)?/art/:id(\\d+)/:slug',
      '/:lang(de)/kunst/:id(\\d+)/:slug',
      '/:lang(fr)/art/:id(\\d+)/:slug',
    ],
    component: 'ArtDetails',
    module: 'frontend/art/detail',
    breadcrumb: '',
    label: 'Art - Details',
    cmsViewRenderSsr: true,
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'artMeta', priority: 2, timeout: 2000, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.artDetails.pageTitle' },
      metaTitle: 'meta.artDetails.title',
      metaKeywords: 'meta.artDetails.keywords',
      metaDescription: 'meta.artDetails.description',
      linkCanonical: 'pathname',
      ogTitle: 'meta.artDetails.title',
      ogDescription: 'meta.artDetails.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/logo.jpg',
      ogUrl: 'pathname',
      twitterCard: 'summary',
    },
    ui: { disableStickyHeader: true },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'artList',
    path: ['/:lang(en)?/art', '/:lang(de)/kunst', '/:lang(fr)/art'],
    component: 'ArtList',
    module: 'frontend/art/index',
    breadcrumb: '',
    filterDomainKey: 'ART',
    label: 'All Art',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'filterPageRouteMeta', priority: 2, timeout: 5000, condition: 'merge' },
      { name: 'filterCategoryMeta', priority: 3, timeout: 2000, condition: 'merge' },
      { name: 'paginationMeta', priority: 4, timeout: 3000, condition: 'concat' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.art.pageTitle' },
      metaTitle: 'meta.art.title',
      metaKeywords: 'meta.art.keywords',
      metaDescription: 'meta.art.description',
      linkCanonical: 'pathname',
      ogTitle: 'meta.art.title',
      ogDescription: 'meta.art.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/main.jpg',
      ogUrl: 'pathname',
      twitterCard: 'summary',
      metaRobots: {
        condition: {
          match: ['location.queryString'],
          params: [
            { name: 'sort', value: '*' },
            { name: 'layout', value: '*' },
          ],
        },
        value: 'noindex',
      },
    },
    exact: false,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'artistProfile',
    path: [
      '/:lang(en)?/artist/:id(\\d+)/:alias',
      '/:lang(de)/kuenstler/:id(\\d+)/:alias',
      '/:lang(fr)/artiste/:id(\\d+)/:alias',
    ],
    component: 'ArtistProfile',
    module: 'frontend/artist/detail',
    breadcrumb: '',
    label: 'Artist - About',
    filterDomainKey: 'ARTIST_ART',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'artistMeta', priority: 2, timeout: 2000, condition: 'merge' },
      { name: 'filterPageRouteMeta', priority: 3, timeout: 3000, condition: 'merge' },
      { name: 'paginationMeta', priority: 4, timeout: 4000, condition: 'concat' },
    ],
    meta: {
      pageTitle: { disabled: true, text: 'meta.artist.pageTitle' },
      metaTitle: 'meta.artist.title',
      metaKeywords: 'meta.artist.keywords',
      metaDescription: 'meta.artist.description',
      linkCanonical: 'pathname',
      ogTitle: 'meta.artist.title',
      ogDescription: 'meta.artist.description',
      ogType: 'profile',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/logo.jpg',
      ogUrl: 'pathname',
      twitterCard: 'summary',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'artistCv',
    path: [
      '/:lang(en)?/artist/:id(\\d+)/:alias/cv',
      '/:lang(de)/kuenstler/:id(\\d+)/:alias/lebenslauf',
      '/:lang(fr)/artiste/:id(\\d+)/:alias/cv',
    ],
    component: 'ArtistCv',
    module: 'frontend/artist/cv',
    breadcrumb: '',
    label: 'Artist - CV',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'artistMeta', priority: 2, timeout: 2000, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.artist.pageTitle' },
      metaTitle: 'meta.artist.title',
      metaKeywords: 'meta.artist.keywords',
      metaDescription: 'meta.artist.description',
      linkCanonical: 'pathname',
      ogTitle: 'meta.artist.title',
      ogDescription: 'meta.artist.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/logo.jpg',
      ogUrl: 'pathname',
      twitterCard: 'summary',
      metaRobots: 'noindex, nofollow',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'artistStudio',
    path: [
      '/:lang(en)?/artist/:id(\\d+)/:alias/studio',
      '/:lang(de)/kuenstler/:id(\\d+)/:alias/studio',
      '/:lang(fr)/artiste/:id(\\d+)/:alias/atelier',
    ],
    component: 'ArtistStudio',
    module: 'frontend/artist/studio',
    breadcrumb: '',
    label: 'Artist - Studio',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'artistMeta', priority: 2, timeout: 2000, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.artist.pageTitle' },
      metaTitle: 'meta.artist.title',
      metaKeywords: 'meta.artist.keywords',
      metaDescription: 'meta.artist.description',
      linkCanonical: 'pathname',
      ogTitle: 'meta.artist.title',
      ogDescription: 'meta.artist.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/logo.jpg',
      ogUrl: 'pathname',
      twitterCard: 'summary',
      metaRobots: 'noindex, nofollow',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'artistNews',
    path: [
      '/:lang(en)?/artist/:id(\\d+)/:alias/news',
      '/:lang(de)/kuenstler/:id(\\d+)/:alias/neuigkeiten',
      '/:lang(fr)/artiste/:id(\\d+)/:alias/nouvelles',
    ],
    component: 'ArtistNews',
    module: 'frontend/artist/news',
    breadcrumb: '',
    label: 'Artist - News',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'artistMeta', priority: 2, timeout: 2000, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.artist.pageTitle' },
      metaTitle: 'meta.artist.title',
      metaKeywords: 'meta.artist.keywords',
      metaDescription: 'meta.artist.description',
      linkCanonical: 'pathname',
      ogTitle: 'meta.artist.title',
      ogDescription: 'meta.artist.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/logo.jpg',
      ogUrl: 'pathname',
      twitterCard: 'summary',
      metaRobots: 'noindex, nofollow',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'artistList',
    path: ['/:lang(en)?/artists', '/:lang(de)/kuenstler', '/:lang(fr)/artistes'],
    component: 'ArtistList',
    module: 'frontend/artist/index',
    breadcrumb: '',
    filterDomainKey: 'ARTIST',
    label: 'All Artists',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'filterPageRouteMeta', priority: 2, timeout: 2000, condition: 'merge' },
      { name: 'filterCategoryMeta', priority: 3, timeout: 2000, condition: 'merge' },
      { name: 'paginationMeta', priority: 4, timeout: 3000, condition: 'concat' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.artist.pageTitle' },
      metaTitle: 'meta.artist.title',
      metaKeywords: 'meta.artist.keywords',
      metaDescription: 'meta.artist.description',
      linkCanonical: 'pathname',
      ogTitle: 'meta.artist.title',
      ogDescription: 'meta.artist.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/main.jpg',
      ogUrl: 'pathname',
      twitterCard: 'summary',
      metaRobots: {
        condition: {
          match: ['location.queryString'],
          params: [
            { name: 'sort', value: '*' },
            { name: 'layout', value: '*' },
          ],
        },
        value: 'noindex',
      },
    },
    exact: false,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'activityList',
    path: ['/:lang(en)?/activity', '/:lang(de)/aktivitaet', '/:lang(fr)/activite'],
    component: 'ActivityList',
    module: 'frontend/activity/index',
    breadcrumb: '',
    label: 'Latest Activity',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'paginationMeta', priority: 2, timeout: 3000, condition: 'concat' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.activity.pageTitle' },
      metaTitle: 'meta.activity.title',
      metaKeywords: 'meta.activity.keywords',
      metaDescription: 'meta.activity.description',
      linkCanonical: 'pathname',
      ogTitle: 'meta.activity.title',
      ogDescription: 'meta.activity.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/main.jpg',
      ogUrl: 'pathname',
      twitterCard: 'summary',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'eventDetail',
    path: [
      '/:lang(en)?/event/:id(\\d+)/:slug',
      '/:lang(de)/veranstaltung/:id(\\d+)/:slug',
      '/:lang(fr)/evenement/:id(\\d+)/:slug',
    ],
    component: 'EventDetails',
    module: 'frontend/event/detail',
    breadcrumb: '',
    label: 'Event - Details',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'eventMeta', priority: 2, timeout: 2000, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.event.pageTitle' },
      metaTitle: 'meta.event.title',
      metaKeywords: 'meta.event.keywords',
      metaDescription: 'meta.event.description',
      linkCanonical: 'pathname',
      ogTitle: 'meta.event.title',
      ogDescription: 'meta.event.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/main.jpg',
      ogUrl: 'pathname',
      twitterCard: 'summary',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'eventList',
    path: ['/:lang(en)?/events', '/:lang(de)/veranstaltungen', '/:lang(fr)/evenements'],
    component: 'EventList',
    module: 'frontend/event/index',
    breadcrumb: '',
    label: 'Events',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'paginationMeta', priority: 2, timeout: 3000, condition: 'concat' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.eventList.pageTitle' },
      metaTitle: 'meta.eventList.title',
      metaKeywords: 'meta.eventList.keywords',
      metaDescription: 'meta.eventList.description',
      linkCanonical: 'pathname',
      ogTitle: 'meta.eventList.title',
      ogDescription: 'meta.eventList.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/main.jpg',
      ogUrl: 'pathname',
      twitterCard: 'summary',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'blog',
    path: ['/:lang(en)?/articles', '/:lang(de)/artikel', '/:lang(fr)/articles'],
    component: 'BlogHome',
    module: 'frontend/article/index',
    breadcrumb: '',
    label: 'Blog',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'blogHomeMeta', priority: 2, timeout: 2000, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: true, text: 'meta.blog.pageTitle' },
      metaTitle: 'meta.blog.title',
      metaKeywords: 'meta.blog.keywords',
      metaDescription: 'meta.blog.description',
      linkCanonical: 'routePath',
      ogTitle: 'meta.blog.title',
      ogDescription: 'meta.blog.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/article.jpg',
      ogUrl: 'routePath',
      twitterCard: 'summary',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'blogCategory',
    path: [
      '/:lang(en)?/articles/:category',
      '/:lang(de)/artikel/:category',
      '/:lang(fr)/articles/:category',
    ],
    component: 'BlogCategory',
    module: 'frontend/article/category',
    breadcrumb: '',
    label: 'Blog - Category',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'blogCategoryMeta', priority: 2, timeout: 2000, condition: 'merge' },
      { name: 'paginationMeta', priority: 3, timeout: 2000, condition: 'concat' },
    ],
    meta: {
      pageTitle: { disabled: true, text: 'meta.blog.pageTitle' },
      metaTitle: 'meta.blogCategory.title',
      metaKeywords: 'meta.blogCategory.keywords',
      metaDescription: 'meta.blogCategory.description',
      linkCanonical: 'routePath',
      ogTitle: 'meta.blogCategory.title',
      ogDescription: 'meta.blogCategory.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/article.jpg',
      ogUrl: 'routePath',
      twitterCard: 'summary',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'blogArticle',
    path: [
      '/:lang(en)?/article/:id(\\d+)/:slug',
      '/:lang(de)/post/:id(\\d+)/:slug',
      '/:lang(fr)/article/:id(\\d+)/:slug',
    ],
    component: 'BlogArticle',
    module: 'frontend/article/detail',
    breadcrumb: '',
    label: 'Blog - Article',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'blogArticleMeta', priority: 2, timeout: 2000, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: true, text: 'meta.blog.pageTitle' },
      metaTitle: 'meta.blogArticle.title',
      metaKeywords: 'meta.blogArticle.keywords',
      metaDescription: 'meta.blogArticle.description',
      linkCanonical: 'routePath',
      ogTitle: 'meta.blogArticle.title',
      ogDescription: 'meta.blogArticle.description',
      ogType: 'article',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/article.jpg',
      ogUrl: 'pathname',
      twitterCard: 'summary',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'guideArticle',
    path: [
      '/:lang(en)?/guide/:id(\\d+)/:slug',
      '/:lang(de)/leitfaden/:id(\\d+)/:slug',
      '/:lang(fr)/guide/:id(\\d+)/:slug',
    ],
    component: 'GuidesArticle',
    module: 'frontend/guide/detail',
    breadcrumb: '',
    label: 'Guides - Article',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'guideArticleMeta', priority: 2, timeout: 2000, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: true, text: 'meta.guides.pageTitle' },
      metaTitle: 'meta.guidesArticle.title',
      metaKeywords: 'meta.guidesArticle.keywords',
      metaDescription: 'meta.guidesArticle.description',
      linkCanonical: 'routePath',
      ogTitle: 'meta.guidesArticle.title',
      ogDescription: 'meta.guidesArticle.description',
      ogType: 'article',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/article.jpg',
      ogUrl: 'pathname',
      twitterCard: 'summary',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'guideCategory',
    path: [
      '/:lang(en)?/guides/:category',
      '/:lang(de)/leitfaeden/:category',
      '/:lang(fr)/guides/:category',
    ],
    component: 'GuidesCategory',
    module: 'frontend/guide/category',
    breadcrumb: '',
    label: 'Guides - Category',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'guideCategoryMeta', priority: 2, timeout: 2000, condition: 'merge' },
      { name: 'paginationMeta', priority: 3, timeout: 2000, condition: 'concat' },
    ],
    meta: {
      pageTitle: { disabled: true, text: 'meta.guides.pageTitle' },
      metaTitle: 'meta.guidesCategory.title',
      metaKeywords: 'meta.guidesCategory.keywords',
      metaDescription: 'meta.guidesCategory.description',
      linkCanonical: 'routePath',
      ogTitle: 'meta.guidesCategory.title',
      ogDescription: 'meta.guidesCategory.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/article.jpg',
      ogUrl: 'routePath',
      twitterCard: 'summary',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'guides',
    path: ['/:lang(en)?/guides', '/:lang(de)/leitfaeden', '/:lang(fr)/guides'],
    component: 'GuidesHome',
    module: 'frontend/guide/index',
    breadcrumb: '',
    label: 'Guides',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'guideLandingMeta', priority: 2, timeout: 2000, condition: 'merge' },
      { name: 'paginationMeta', priority: 3, timeout: 2000, condition: 'concat' },
    ],
    meta: {
      pageTitle: { disabled: true, text: 'meta.guides.pageTitle' },
      metaTitle: 'meta.guides.title',
      metaKeywords: 'meta.guides.keywords',
      metaDescription: 'meta.guides.description',
      linkCanonical: 'routePath',
      ogTitle: 'meta.guides.title',
      ogDescription: 'meta.guides.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/article.jpg',
      ogUrl: 'routePath',
      twitterCard: 'summary',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'collectionDetail',
    path: [
      '/:lang(en)?/collection/:id(\\d+)/:slug',
      '/:lang(de)/sammlung/:id(\\d+)/:slug',
      '/:lang(fr)/collection/:id(\\d+)/:slug',
    ],
    component: 'CollectionDetails',
    module: 'frontend/collection/detail',
    breadcrumb: '',
    filterDomainKey: 'COLLECTION_ART',
    label: 'Collection - Details',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'filterPageRouteMeta', priority: 2, timeout: 2000, condition: 'merge' },
      { name: 'collectionMeta', priority: 3, timeout: 2000, condition: 'merge' },
      { name: 'collectionLocaleMeta', priority: 4, timeout: 4000, condition: 'concat' },
      { name: 'paginationMeta', priority: 5, timeout: 3000, condition: 'concat' },
    ],
    meta: {
      pageTitle: { disabled: true, text: 'meta.collectionDetails.pageTitle' },
      metaTitle: 'meta.collectionDetails.title',
      metaKeywords: 'meta.collectionDetails.keywords',
      metaDescription: 'meta.collectionDetails.description',
      linkCanonical: 'routePath',
      ogTitle: 'meta.collectionDetails.title',
      ogDescription: 'meta.collectionDetails.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/artwork.jpg',
      ogUrl: 'pathname',
      twitterCard: 'summary',
      metaRobots: {
        condition: {
          match: ['location.queryString'],
          params: [
            { name: 'sort', value: '*' },
            { name: 'layout', value: '*' },
            { name: 'page', value: '*' },
          ],
        },
        value: 'noindex',
      },
    },
    exact: false,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'collectionList',
    path: ['/:lang(en)?/collections', '/:lang(de)/sammlungen', '/:lang(fr)/collections'],
    component: 'CollectionList',
    module: 'frontend/collection/index',
    breadcrumb: '',
    filterDomainKey: 'COLLECTION',
    label: 'All Collections',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'filterPageRouteMeta', priority: 2, timeout: 2000, condition: 'merge' },
      { name: 'paginationMeta', priority: 3, timeout: 2000, condition: 'concat' },
    ],
    meta: {
      pageTitle: { disabled: true, text: 'meta.collectionList.pageTitle' },
      metaTitle: 'meta.collectionList.title',
      metaKeywords: 'meta.collectionList.keywords',
      metaDescription: 'meta.collectionList.description',
      linkCanonical: 'routePath',
      ogTitle: 'meta.collectionList.title',
      ogDescription: 'meta.collectionList.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/main.jpg',
      ogUrl: 'routePath',
      twitterCard: 'summary',
    },
    exact: false,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'cart',
    path: [
      '/:lang(en)?/:cartType(cart|rent)',
      '/:lang(de)/:cartType(warenkorb|mieten)',
      '/:lang(fr)/:cartType(panier|louer)',
    ],
    component: 'Cart',
    module: 'frontend/cart/index',
    breadcrumb: '',
    label: 'Shopping cart',
    cartMenuAction: 'page',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.common.pageTitle' },
      metaTitle: 'meta.cart.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
      metaRobots: 'noindex, nofollow',
      linkCanonical: 'pathname',
    },
    ui: { disallowGoogleAuthPrompt: true, disableStickyHeader: true },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'checkout',
    path: [
      '/:lang(en)?/checkout/:cartType(buy|rent)',
      '/:lang(de)/kasse/:cartType(kaufen|mieten)',
      '/:lang(fr)/caisse/:cartType(acheter|louer)',
    ],
    component: 'Checkout',
    module: 'frontend/cart/checkout',
    breadcrumb: '',
    label: 'Checkout',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.common.pageTitle' },
      metaTitle: 'meta.checkout.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
      metaRobots: 'noindex, nofollow',
      linkCanonical: 'pathname',
    },
    ui: { disallowGoogleAuthPrompt: true, disableStickyHeader: true },
    exact: true,
    strict: false,
    acl: { roles: ['user'], authPage: { key: 'checkoutAuth', options: { authType: 'login' } } },
  },
  {
    key: 'checkoutAuth',
    path: [
      '/:lang(en)?/checkout/:authType(login|join)',
      '/:lang(de)/kasse/:authType(anmelden|teilnehmen)',
      '/:lang(fr)/caisse/:authType(connexion|rejoindre)',
    ],
    component: 'CheckoutAuth',
    module: 'frontend/cart/auth',
    breadcrumb: '',
    label: 'Checkout',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: true, text: 'meta.common.pageTitle' },
      metaTitle: 'meta.checkout.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
      metaRobots: 'noindex, nofollow',
      linkCanonical: 'pathname',
    },
    ui: { disallowGoogleAuthPrompt: true, disableStickyHeader: true },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'orderConfirmation',
    path: [
      '/:lang(en)?/order/confirm/:orderId(\\d+)',
      '/:lang(de)/bestellen/bestaetigen/:orderId(\\d+)',
      '/:lang(fr)/commande/confirmer/:orderId(\\d+)',
    ],
    component: 'OrderConfirmation',
    module: 'frontend/order/confirm',
    breadcrumb: '',
    label: 'Checkout Confirmation',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.common.pageTitle' },
      metaTitle: 'meta.checkout.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
      metaRobots: 'noindex, nofollow',
      linkCanonical: 'pathname',
    },
    exact: true,
    strict: false,
    acl: { roles: ['user'], authPage: { key: 'checkoutAuth', options: { authType: 'login' } } },
  },
  {
    key: 'quizStart',
    path: ['/:lang(en)?/quiz/start', '/:lang(de)/test/anfang', '/:lang(fr)/quiz/debuter'],
    component: 'QuizStart',
    module: 'frontend/quiz/index',
    breadcrumb: '',
    label: 'Quiz - Start',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.quiz.pageTitle' },
      metaTitle: 'meta.quiz.title',
      metaKeywords: 'meta.quiz.keywords',
      metaDescription: 'meta.quiz.description',
      linkCanonical: 'pathname',
      ogTitle: 'meta.quiz.ogTitle',
      ogDescription: 'meta.quiz.ogDescription',
      ogType: 'website',
      ogUrl: 'pathname',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/logo.jpg',
      twitterCard: 'summary',
    },
    ui: { hideChat: true },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'quizQuestions',
    path: ['/:lang(en)?/quiz/questions', '/:lang(de)/test/fragen', '/:lang(fr)/quiz/questions'],
    component: 'QuizQuestions',
    module: 'frontend/quiz/index',
    breadcrumb: '',
    label: 'Quiz - Questions',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.quizQuestions.pageTitle' },
      metaTitle: 'meta.quizQuestions.title',
      metaKeywords: 'meta.quizQuestions.keywords',
      metaDescription: 'meta.quizQuestions.description',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/logo.jpg',
      metaRobots: 'noindex, nofollow',
      linkCanonical: 'pathname',
    },
    ui: { hideChat: true },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'quizResults',
    path: ['/:lang(en)?/quiz/result', '/:lang(de)/test/ergebnis', '/:lang(fr)/quiz/resultat'],
    component: 'QuizResult',
    module: 'frontend/quiz/index',
    breadcrumb: '',
    label: 'Quiz - Results',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.quizResult.pageTitle' },
      metaTitle: 'meta.quizResult.title',
      metaKeywords: 'meta.quizResult.keywords',
      metaDescription: 'meta.quizResult.description',
      metaRobots: 'noindex, nofollow',
      linkCanonical: 'pathnameAndQueryString',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/logo.jpg',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'quizProfile',
    path: [
      '/:lang(en)?/quiz/personality/:slug(\\w+)',
      '/:lang(de)/test/persoenlichkeit/:slug(\\w+)',
      '/:lang(fr)/quiz/personnalite/:slug(\\w+)',
    ],
    component: 'QuizProfile',
    module: 'frontend/quiz/personality',
    breadcrumb: '',
    label: 'Quiz - Personality',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'quizProfileMeta', priority: 2, timeout: 2000, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.quizProfile.pageTitle' },
      metaTitle: 'meta.quizProfile.title',
      metaKeywords: 'meta.quizProfile.keywords',
      metaDescription: 'meta.quizProfile.description',
      linkCanonical: 'pathname',
      ogTitle: 'meta.quizProfile.title',
      ogDescription: 'meta.quizProfile.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/logo.jpg',
      ogUrl: 'pathname',
      twitterCard: 'summary',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'quizRegister',
    path: [
      '/:lang(en)?/quiz/register',
      '/:lang(de)/test/registrieren',
      '/:lang(fr)/quiz/sinscrire',
    ],
    component: 'QuizRegister',
    module: 'frontend/quiz/index',
    breadcrumb: '',
    label: 'Quiz - Register',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.quizRegister.pageTitle' },
      metaTitle: 'meta.quizRegister.title',
      metaKeywords: 'meta.quizRegister.keywords',
      metaDescription: 'meta.quizRegister.description',
      metaRobots: 'noindex, nofollow',
    },
    ui: { hideChat: true, disallowGoogleAuthPrompt: true, disableStickyHeader: true },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'quizLogin',
    path: ['/:lang(en)?/quiz/login', '/:lang(de)/test/anmelden', '/:lang(fr)/quiz/connexion'],
    component: 'QuizLogin',
    module: 'frontend/quiz/index',
    breadcrumb: '',
    label: 'Quiz - Login',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.quizLogin.pageTitle' },
      metaTitle: 'meta.quizLogin.title',
      metaKeywords: 'meta.quizLogin.keywords',
      metaDescription: 'meta.quizLogin.description',
      metaRobots: 'noindex, nofollow',
    },
    ui: { hideChat: true, disallowGoogleAuthPrompt: true, disableStickyHeader: true },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'myWishlist',
    path: ['/:lang(en)?/me/wishlist', '/:lang(de)/ich/wunschliste', '/:lang(fr)/moi/wishlist'],
    component: 'MeWishlist',
    module: 'frontend/me/wishlist',
    breadcrumb: '',
    filterDomainKey: 'FAVORITES_ART',
    label: 'My Wishlist',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'filterPageRouteMeta', priority: 2, timeout: 2000, condition: 'merge' },
      { name: 'paginationMeta', priority: 3, timeout: 3000, condition: 'concat' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.myWishlist.pageTitle' },
      metaTitle: 'meta.myWishlist.title',
      metaKeywords: 'meta.myWishlist.keywords',
      metaDescription: 'meta.myWishlist.description',
      linkCanonical: 'routePath',
      ogTitle: 'meta.myWishlist.title',
      ogDescription: 'meta.myWishlist.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/artwork.jpg',
      ogUrl: 'pathname',
      twitterCard: 'summary',
      metaRobots: 'noindex, nofollow',
    },
    exact: false,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'myOffers',
    path: ['/:lang(en)?/me/offers', '/:lang(de)/ich/angebote', '/:lang(fr)/moi/offres'],
    component: 'MeOffers',
    module: 'frontend/me/offers',
    breadcrumb: '',
    label: 'My Offers',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.myOffers.pageTitle' },
      metaTitle: 'meta.myOffers.title',
      metaKeywords: 'meta.myOffers.keywords',
      metaDescription: 'meta.myOffers.description',
      linkCanonical: 'routePath',
      ogTitle: 'meta.myOffers.title',
      ogDescription: 'meta.myOffers.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/artwork.jpg',
      ogUrl: 'pathname',
      twitterCard: 'summary',
      metaRobots: 'noindex, nofollow',
    },
    exact: false,
    strict: false,
    acl: { roles: ['basic'] },
  },
  {
    key: 'myOffer',
    path: [
      '/:lang(en)?/me/offer/:id(\\d+)',
      '/:lang(de)/ich/angebot/:id(\\d+)',
      '/:lang(fr)/moi/offrir/:id(\\d+)',
    ],
    component: 'MeOfferDetails',
    module: 'frontend/me/offer',
    breadcrumb: '',
    label: 'My Offer Details',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.myOffer.pageTitle' },
      metaTitle: 'meta.myOffer.title',
      metaKeywords: 'meta.myOffer.keywords',
      metaDescription: 'meta.myOffer.description',
      linkCanonical: 'routePath',
      ogTitle: 'meta.myOffer.title',
      ogDescription: 'meta.myOffer.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/artwork.jpg',
      ogUrl: 'pathname',
      twitterCard: 'summary',
      metaRobots: 'noindex, nofollow',
    },
    exact: false,
    strict: false,
    acl: { roles: ['basic'] },
  },
  {
    key: 'myArtists',
    path: ['/:lang(en)?/me/artists', '/:lang(de)/ich/kuenstler', '/:lang(fr)/moi/artistes'],
    component: 'MeArtists',
    module: 'frontend/me/artists',
    breadcrumb: '',
    filterDomainKey: 'FAVORITES_ARTIST',
    label: 'My Followed Artists',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
      { name: 'filterPageRouteMeta', priority: 2, timeout: 2000, condition: 'merge' },
      { name: 'paginationMeta', priority: 3, timeout: 3000, condition: 'concat' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.myArtists.pageTitle' },
      metaTitle: 'meta.myArtists.title',
      metaKeywords: 'meta.myArtists.keywords',
      metaDescription: 'meta.myArtists.description',
      linkCanonical: 'routePath',
      ogTitle: 'meta.myArtists.title',
      ogDescription: 'meta.myArtists.description',
      ogType: 'website',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/artwork.jpg',
      ogUrl: 'pathname',
      twitterCard: 'summary',
      metaRobots: 'noindex, nofollow',
    },
    exact: false,
    strict: false,
    acl: { roles: ['user'] },
  },
  {
    key: 'myProfile',
    path: ['/:lang(en)?/me/profile', '/:lang(de)/ich/profil', '/:lang(fr)/moi/profil'],
    component: 'MeProfile',
    module: 'frontend/me/profile',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.profile.pageTitle' },
      metaTitle: 'meta.profile.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
      linkCanonical: 'pathname',
      metaRobots: 'noindex, nofollow',
    },
    exact: true,
    strict: false,
    acl: { roles: ['user'] },
  },
  {
    key: 'myAccount',
    path: ['/:lang(en)?/me/account', '/:lang(de)/ich/konto', '/:lang(fr)/moi/compte'],
    component: 'MeAccount',
    module: 'frontend/me/account',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.account.pageTitle' },
      metaTitle: 'meta.account.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
      linkCanonical: 'pathname',
      metaRobots: 'noindex, nofollow',
    },
    exact: true,
    strict: false,
    acl: { roles: ['user'] },
  },
  {
    key: 'myPrivacy',
    path: ['/:lang(en)?/me/privacy', '/:lang(de)/ich/datenschutz', '/:lang(fr)/moi/vie-privee'],
    component: 'MePrivacy',
    module: 'frontend/me/privacy',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.privacy.pageTitle' },
      metaTitle: 'meta.privacy.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
      linkCanonical: 'pathname',
      metaRobots: 'noindex, nofollow',
    },
    exact: true,
    strict: false,
    acl: { roles: ['user'] },
  },
  {
    key: 'login',
    path: ['/:lang(en)?/login', '/:lang(de)/anmelden', '/:lang(fr)/connexion'],
    component: 'Login',
    module: 'frontend/auth/index',
    breadcrumb: '',
    label: 'Login',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.login.pageTitle' },
      metaTitle: 'meta.login.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/logo.jpg',
      metaRobots: 'noindex, nofollow',
    },
    ui: { disallowGoogleAuthPrompt: true, disableStickyHeader: true },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'register',
    path: ['/:lang(en)?/join', '/:lang(de)/teilnehmen', '/:lang(fr)/rejoindre'],
    component: 'Register',
    module: 'frontend/auth/index',
    breadcrumb: '',
    label: 'Register',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.register.pageTitle' },
      metaTitle: 'meta.register.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/logo.jpg',
      metaRobots: 'noindex, nofollow',
    },
    ui: { disallowGoogleAuthPrompt: true, disableStickyHeader: true },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'forgotPassword',
    path: [
      '/:lang(en)?/password/forgot',
      '/:lang(de)/passwort/vergessen',
      '/:lang(fr)/mot-de-passe/oublie',
    ],
    component: 'ForgotPassword',
    module: 'frontend/auth/index',
    breadcrumb: '',
    label: 'Forgot Password',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.forgotPassword.pageTitle' },
      metaTitle: 'meta.forgotPassword.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/logo.jpg',
      metaRobots: 'noindex, nofollow',
    },
    ui: { disallowGoogleAuthPrompt: true, disableStickyHeader: true },
    exact: true,
    strict: false,
    acl: { roles: { visitor: 'allow', user: 'disallow' }, forward: 'myAccount' },
  },
  {
    key: 'resetPassword',
    path: [
      '/:lang(en)?/password/reset',
      '/:lang(de)/passwort/zuruecksetzen',
      '/:lang(fr)/mot-de-passe/reinitialiser',
    ],
    component: 'ResetPassword',
    module: 'frontend/auth/index',
    breadcrumb: '',
    label: 'Forgot Password',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.resetPassword.pageTitle' },
      metaTitle: 'meta.resetPassword.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
      ogImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/static/og/logo.jpg',
      metaRobots: 'noindex, nofollow',
    },
    ui: { disallowGoogleAuthPrompt: true, disableStickyHeader: true },
    exact: true,
    strict: false,
    acl: { roles: { visitor: 'allow', user: 'disallow' }, forward: 'myAccount' },
  },
  {
    key: 'signout',
    path: ['/:lang(en)?/signout', '/:lang(de)/abmelden', '/:lang(fr)/se-deconnecter'],
    component: 'Signout',
    module: 'frontend/auth/index',
    breadcrumb: '',
    label: 'Signout',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'pageMeta', priority: 1, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.signout.pageTitle' },
      metaTitle: 'meta.signout.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
      metaRobots: 'noindex, nofollow',
    },
    ui: { disallowGoogleAuthPrompt: true, disableStickyHeader: true },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'notfound',
    component: 'CmsRouter',
    fallbackComponent: 'NotFound',
    module: null,
    breadcrumb: '',
    label: '',
    metaSubscriptions: [
      { name: 'routerMeta' },
      { name: 'errorMeta', priority: 1, timeout: 2000, condition: 'merge' },
      { name: 'cmsMeta', priority: 1, timeout: 2000, condition: 'merge' },
    ],
    meta: {
      pageTitle: { disabled: false, text: 'meta.common.pageTitle' },
      metaTitle: 'meta.common.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
];
export const errors = {
  errorsTypes: {
    __defaultError: {
      status: 500,
      code: 'FEND_1001',
      type: '__defaultError',
      title: 'errors.default.title',
      detail: 'errors.default.details',
      handler: 'notification',
      level: 10,
      expire: 15,
      image: 'https://d1ee3oaj5b5ueh.cloudfront.net/2017/10/59dcad93c93c0.jpeg',
      log: true,
    },
    __internalError: {
      status: 500,
      code: 'FEND_1002',
      type: 'JSError',
      handler: 'silent',
      level: 10,
    },
    CoreException: { status: 500, code: 'FEND_1010', type: 'CoreException' },
    ForbiddenException: {
      status: 403,
      type: 'ForbiddenException',
      title: 'errors.forbiddenException.title',
      detail: 'errors.forbiddenException.details',
    },
    InvalidParamException: { status: 400, type: 'InvalidParamException' },
    MissingParamException: { status: 400, type: 'MissingParamException' },
    PageNotFound: {
      status: 404,
      type: 'PageNotFound',
      title: 'errors.pageNotFound.title',
      detail: 'errors.pageNotFound.details',
      handler: 'errorPage',
    },
    ResourceNotFoundException: {
      status: 404,
      type: 'ResourceNotFoundException',
      title: 'errors.pageNotFound.title',
      detail: 'errors.pageNotFound.details',
      handler: 'errorPage',
    },
    NetworkError: {
      status: 503,
      type: 'NetworkError',
      title: 'errors.networkError.title',
      detail: 'errors.networkError.details',
    },
    TimeoutError: {
      status: 503,
      type: 'NetworkError',
      title: 'errors.timeoutError.title',
      detail: 'errors.timeoutError.details',
    },
    ApiServerError: {
      type: 'ApiServerError',
      title: 'errors.apiServerError.title',
      detail: 'errors.apiServerError.details',
      handler: 'errorPage',
    },
    InvalidAuthorizationToken: {
      type: 'InvalidAuthorizationToken',
      title: 'errors.invalidToken.title',
      detail: 'errors.invalidToken.details',
    },
    InvalidAuthorizeParameters: { type: 'InvalidAuthorizeParameters' },
    AlreadyExistsException: {
      status: 409,
      type: 'AlreadyExistsException',
      handler: 'notification',
      level: 20,
      log: false,
    },
    AuthInvalidPassword: { type: 'AuthInvalidPassword', level: 20, log: false },
    AuthNoExistingUser: { type: 'AuthNoExistingUser', level: 20, log: false },
    ExistsInOtherDomain: { type: 'ExistsInOtherDomain', level: 20, log: false },
    PaymentMethodException: {
      status: 500,
      type: 'PaymentMethodException',
      handler: 'silent',
      level: 10,
      log: true,
    },
  },
  overwriteErrors: [
    {
      matchConditions: { status: 404, 'additional.path[0]': 'readPageMeta' },
      overwriteFields: { handler: 'silent', log: false },
    },
    {
      matchConditions: { status: 404, 'additional.path[0]': 'readMarketingCampaign' },
      overwriteFields: { handler: 'silent', log: false },
    },
    {
      matchConditions: { 'additional.path[0]': 'readMarketingCampaign' },
      overwriteFields: { handler: 'silent', log: true },
    },
    {
      matchConditions: { status: 403, 'additional.path[0]': 'readQuizResponse' },
      overwriteFields: { handler: 'silent', log: false },
    },
    {
      matchConditions: { status: 404, 'additional.path[0]': 'readQuizResponse' },
      overwriteFields: { handler: 'silent', log: false },
    },
    {
      matchConditions: { 'additional.path[0]': 'readQuizResponse' },
      overwriteFields: { handler: 'errorPage', log: true },
    },
    {
      matchConditions: {
        type: 'ResourceNotFoundException',
        status: 404,
        'additional.path[0]': 'readArt',
      },
      overwriteFields: { handler: 'silent', log: false },
    },
    {
      matchConditions: { 'additional.path[0]': 'readArt' },
      overwriteFields: { handler: 'errorPage', log: true },
    },
    {
      matchConditions: {
        type: 'ResourceNotFoundException',
        status: 404,
        'additional.path[0]': 'combinedArtSearchAndCategory',
      },
      overwriteFields: { handler: 'silent', log: false },
    },
    {
      matchConditions: { status: 404, 'additional.path[0]': 'readCollection' },
      overwriteFields: { handler: 'silent', log: false },
    },
    {
      matchConditions: { 'additional.path[0]': 'filterActivityTrending' },
      overwriteFields: { handler: 'notification', log: true },
    },
    {
      matchConditions: { 'additional.path[0]': 'readCart' },
      overwriteFields: { handler: 'notification', log: true },
    },
    {
      matchConditions: { status: 401, code: '35001', 'additional.path[0]': 'createOrder' },
      overwriteFields: { handler: 'silent', level: 20, log: false },
    },
    {
      matchConditions: {
        status: 404,
        type: 'ResourceNotFoundException',
        'additional.path[0]': 'updateCart',
      },
      overwriteFields: { handler: 'notification', level: 10, log: true },
    },
    {
      matchConditions: { 'additional.path[0]': 'createValidation' },
      overwriteFields: { handler: 'notification', level: 10, log: false },
    },
    {
      matchConditions: { 'additional.path[0]': 'resetPassword' },
      overwriteFields: { handler: 'notification', level: 10, log: false },
    },
    {
      matchConditions: {
        status: 400,
        type: 'InvalidParamException',
        detail: 'The provided user privacy setting are unmodified',
        'additional.path[0]': 'updateUserPrivacySettings',
      },
      overwriteFields: { handler: 'silent', level: 20, log: false },
    },
    {
      matchConditions: { 'additional.path[0]': 'updateUserPrivacySettings' },
      overwriteFields: { handler: 'notification', level: 10, log: false },
    },
    {
      matchConditions: { 'additional.path[0]': 'updateUserMailingLists' },
      overwriteFields: { handler: 'notification', level: 10, log: false },
    },
    {
      matchConditions: { 'additional.path[0]': 'deleteFollow', type: 'ResourceNotFoundException' },
      overwriteFields: { handler: 'notification', level: 10, log: false },
    },
    {
      matchConditions: { 'additional.path[0]': 'listUserOffers' },
      overwriteFields: { handler: 'notification', log: true },
    },
    {
      matchConditions: { 'additional.path[0]': 'readUserOffer' },
      overwriteFields: { handler: 'errorPage', log: true },
    },
    {
      matchConditions: {
        status: 404,
        type: 'ResourceNotFoundException',
        'additional.path[0]': 'bootstrapApp',
      },
      overwriteFields: {
        handler: 'notification',
        log: false,
        title: 'errors.notFoundVisitor.title',
        detail: 'errors.notFoundVisitor.details',
      },
    },
    {
      matchConditions: {
        status: 404,
        type: 'ResourceNotFoundException',
        'additional.path[0]': 'readMe',
      },
      overwriteFields: {
        handler: 'notification',
        log: true,
        title: 'errors.notFoundVisitor.title',
        detail: 'errors.notFoundVisitor.details',
      },
    },
  ],
};
export const messages = {
  applicationMessages: [
    {
      code: 'redirect/404',
      content: 'messages.application.error404',
      location: 'top',
      priority: 5,
      options: {
        dismiss: false,
        timer: 20,
        contentType: 'text',
        type: 'cookie/session',
        httpStatusCode: 404,
      },
      conditions: [{ 'query:message:eq': '404' }],
    },
    {
      code: 'referal/artfetch',
      content: 'messages.application.artfetch',
      location: 'top',
      priority: 6,
      options: { dismiss: true, type: 'visitor/setting', contentType: 'text' },
      conditions: [{ 'query:marketing:eq': 'artfetch-seo' }],
    },
    {
      code: 'referal/kazoart',
      content: 'messages.application.kazoart',
      location: 'top',
      priority: 6,
      options: {
        dismiss: true,
        showIcon: false,
        type: 'cookie/persistent',
        contentType: 'component',
        componentName: 'Kazoart',
      },
      conditions: [{ 'query:marketing:eq': 'kazoart-seo' }],
    },
    {
      code: 'referal/kazoart',
      content: 'messages.application.kazoart',
      location: 'top',
      priority: 6,
      options: {
        dismiss: true,
        showIcon: false,
        type: 'cookie/persistent',
        contentType: 'component',
        componentName: 'Kazoart',
      },
      conditions: [{ 'path:regex': '^(\\/fr\\/?$)|(\\/fr\\/?\\?[^\\s])|(\\/fr(\\/[^\\s]))' }],
    },
    {
      code: 'website/exhibition/kinaesthesia',
      content: 'messages.application.kinaesthesia',
      location: 'top',
      priority: 7,
      options: { dismiss: true, showIcon: false, type: 'cookie/persistent', contentType: 'html' },
      conditions: [
        { 'date:today:gte': '2024-07-03T00:00:00Z' },
        { 'date:today:lte': '2024-08-03T23:59:59Z' },
      ],
    },
    {
      code: 'website/consent',
      location: 'bottom',
      priority: 10,
      options: {
        dismiss: true,
        hideDismissIcon: true,
        showIcon: false,
        type: 'cookie/persistent',
        contentType: 'component',
        componentName: 'ConsentMessage',
        componentProps: {
          content: [
            {
              showForCountryCode: [
                'GB',
                'AT',
                'BE',
                'BG',
                'HR',
                'CY',
                'CZ',
                'DK',
                'EE',
                'FI',
                'FR',
                'DE',
                'GR',
                'HU',
                'IE',
                'IT',
                'LV',
                'LT',
                'LU',
                'MT',
                'NL',
                'PL',
                'PT',
                'RO',
                'SK',
                'SI',
                'ES',
                'SE',
              ],
              message: 'messages.application.cookie.message.long',
              reject: 'messages.application.cookie.reject',
              accept: 'messages.application.cookie.accept',
            },
            {
              showForCountryCode: ['US', 'CA'],
              message: 'messages.application.cookie.message.short',
              accept: 'common.ok',
            },
          ],
        },
      },
      conditions: [
        { 'useragent:device:type:neq': 'bot' },
        {
          'customData:auth:shippingCountryCode:eq': [
            'GB',
            'AT',
            'BE',
            'BG',
            'HR',
            'CY',
            'CZ',
            'DK',
            'EE',
            'FI',
            'FR',
            'DE',
            'GR',
            'HU',
            'IE',
            'IT',
            'LV',
            'LT',
            'LU',
            'MT',
            'NL',
            'PL',
            'PT',
            'RO',
            'SK',
            'SI',
            'ES',
            'SE',
            'US',
            'CA',
          ],
        },
      ],
    },
    {
      code: 'website/browser/compatibility/ie',
      content: 'messages.application.browser_support',
      link: 'https://help.riseart.com/article/84-browser-support',
      location: 'top',
      priority: 20,
      options: { dismiss: true, type: 'cookie/persistent', contentType: 'html' },
      conditions: [
        { 'useragent:device:type:neq': 'bot' },
        { 'useragent:browser:name:eq': 'IE' },
        { 'useragent:browser:version:lte': 11 },
      ],
    },
    {
      code: 'website/browser/compatibility/chrome',
      content: 'messages.application.browser_support',
      link: 'https://help.riseart.com/article/84-browser-support',
      location: 'top',
      priority: 20,
      options: { dismiss: true, type: 'cookie/persistent', contentType: 'html' },
      conditions: [
        { 'useragent:device:type:neq': 'bot' },
        { 'useragent:browser:name:eq': 'Chrome' },
        { 'useragent:browser:version:lt': 58 },
      ],
    },
    {
      code: 'website/browser/compatibility/firefox',
      content: 'messages.application.browser_support',
      link: 'https://help.riseart.com/article/84-browser-support',
      location: 'top',
      priority: 20,
      options: { dismiss: true, type: 'cookie/persistent', contentType: 'html' },
      conditions: [
        { 'useragent:device:type:neq': 'bot' },
        { 'useragent:browser:name:eq': 'Firefox' },
        { 'useragent:browser:version:lt': 54 },
      ],
    },
    {
      code: 'website/browser/compatibility/safari',
      content: 'messages.application.browser_support',
      link: 'https://help.riseart.com/article/84-browser-support',
      location: 'top',
      priority: 20,
      options: { dismiss: true, type: 'cookie/persistent', contentType: 'html' },
      conditions: [
        { 'useragent:device:type:neq': 'bot' },
        { 'useragent:browser:name:eq': 'Safari' },
        { 'useragent:browser:version:lt': 10 },
      ],
    },
    {
      code: 'website/promotion/christmas-delivery-2024',
      content: 'messages.application.christmasDelivery',
      link: null,
      location: 'top',
      priority: 5,
      options: {
        dismiss: true,
        showIcon: false,
        type: 'cookie/session',
        localTime: true,
        contentType: 'component',
        componentName: 'ChristmasDelivery',
      },
      conditions: [
        { 'date:today:gte': '2024-12-03T00:00:00Z' },
        { 'date:today:lte': '2024-12-17T23:59:59Z' },
      ],
    },
    {
      code: 'website/promotion/christmas-gift-2024',
      content: 'messages.application.christmasGift',
      link: null,
      location: 'top',
      priority: 11,
      options: {
        dismiss: true,
        showIcon: false,
        type: 'cookie/session',
        localTime: true,
        contentType: 'component',
        componentName: 'ChristmasGift',
      },
      conditions: [
        { 'date:today:gte': '2024-12-18T00:00:00Z' },
        { 'date:today:lte': '2024-12-25T23:59:59Z' },
      ],
    },
    {
      code: 'website/promotion/artist_spotlight_2024',
      content: 'messages.application.artistSpotlight',
      location: 'top',
      priority: 1,
      options: {
        dismiss: false,
        border: false,
        type: 'visitor/setting',
        contentType: 'component',
        componentName: 'ArtistSpotlightMessage',
        componentProps: {
          showCountdown: false,
          link: {
            'en-GB': { id: 1725, slug: 'artist-spotlight-series' },
            'de-DE': { id: 1725, slug: 'kunstler-spotlight-serie' },
            'fr-FR': { id: 1725, slug: '5-artistes-a-la-une' },
          },
        },
        localTime: true,
      },
      conditions: [
        { 'date:today:gte': '2024-04-24T00:00:00Z' },
        { 'date:today:lte': '2024-04-26T23:59:59Z' },
      ],
    },
    {
      code: 'website/promotion/artist_spotlight_extended_2024',
      content: 'messages.application.artistSpotlight',
      location: 'top',
      priority: 1,
      options: {
        dismiss: false,
        border: false,
        type: 'visitor/setting',
        contentType: 'component',
        componentName: 'ArtistSpotlightMessage',
        componentProps: {
          date: '2024-04-28T23:59:59Z',
          localTime: true,
          extended: true,
          link: {
            'en-GB': { id: 1725, slug: 'artist-spotlight-series' },
            'de-DE': { id: 1725, slug: 'kunstler-spotlight-serie' },
            'fr-FR': { id: 1725, slug: '5-artistes-a-la-une' },
          },
        },
        localTime: true,
      },
      conditions: [
        { 'date:today:gte': '2024-04-27T00:00:00Z' },
        { 'date:today:lte': '2024-04-28T23:59:59Z' },
      ],
    },
    {
      code: 'grid/card/art/visual-search',
      content: null,
      link: null,
      location: 'shopPage',
      priority: 30,
      options: {
        dismiss: false,
        listPosition: { mobile: 5, tablet: 8, desktop: 10 },
        contentType: 'component',
        componentName: 'PromotionGridCard',
        componentProps: {
          title: 'components.appMessages.promotions.visualSearch.title',
          text: 'components.appMessages.promotions.visualSearch.text',
          icon: 'visuallySimilar',
          link: {
            type: 'button',
            text: 'components.appMessages.promotions.visualSearch.action',
            href: { source: 'urlAssembler', key: 'artList', options: { search: { v: '' } } },
            external: true,
          },
        },
      },
      conditions: [{ 'query:v:nexists': null }],
    },
    {
      code: 'grid/card/art/returns',
      content: null,
      link: null,
      location: 'shopPage',
      priority: 30,
      options: {
        dismiss: false,
        listPosition: { mobile: 10, tablet: 16, desktop: 20 },
        contentType: 'component',
        componentName: 'PromotionGridCard',
        componentProps: {
          title: 'components.appMessages.promotions.returns.title',
          text: null,
          icon: 'freeReturn',
          link: {
            type: 'button',
            text: 'common.learnMore',
            href: { source: 'config', key: 'externalUrls.freeReturns' },
            target: '_blank',
            external: true,
          },
        },
      },
      conditions: [],
    },
    {
      code: 'grid/card/art/reviews',
      content: null,
      link: null,
      location: 'shopPage',
      priority: 30,
      options: {
        dismiss: false,
        listPosition: { mobile: 15, tablet: 24, desktop: 30 },
        contentType: 'component',
        componentName: 'PromotionGridCard',
        componentProps: {
          title: 'components.appMessages.promotions.reviews.title',
          text: 'components.appMessages.promotions.reviews.text',
          icon: 'reviews',
          link: {
            type: 'button',
            text: 'components.appMessages.promotions.reviews.action',
            href: { source: 'config', key: 'externalUrls.sohoHome' },
            target: '_blank',
            external: true,
          },
        },
      },
      conditions: [],
    },
    {
      code: 'grid/card/art/filters',
      content: null,
      link: null,
      location: 'shopPage',
      priority: 30,
      options: {
        dismiss: false,
        listPosition: { mobile: 20, tablet: 32, desktop: 40 },
        contentType: 'component',
        componentName: 'PromotionGridCard',
        componentProps: {
          title: 'components.appMessages.promotions.filters.title',
          text: 'components.appMessages.promotions.filters.text',
          icon: 'filters',
          link: {
            type: 'button',
            text: 'components.appMessages.promotions.filters.action',
            href: { source: 'hash', key: 'filters' },
            external: true,
          },
        },
      },
      conditions: [],
    },
    {
      code: 'grid/card/art/advisors',
      content: null,
      link: null,
      location: 'shopPage',
      priority: 30,
      options: {
        dismiss: false,
        listPosition: { mobile: 25, tablet: 40, desktop: 50 },
        contentType: 'component',
        componentName: 'PromotionGridCard',
        componentProps: {
          title: 'components.appMessages.promotions.advisors.title',
          text: null,
          icon: 'customer',
          link: {
            type: 'button',
            text: 'components.appMessages.promotions.advisors.action',
            href: { source: 'config', key: 'externalUrls.talkToCurator' },
            target: '_blank',
            external: true,
          },
        },
      },
      conditions: [],
    },
    {
      code: 'wishlist/card/visual-search',
      content: null,
      link: null,
      location: 'wishlist',
      priority: 30,
      options: {
        dismiss: false,
        listPosition: 5,
        contentType: 'component',
        componentName: 'PromotionGridCard',
        componentProps: {
          title: 'components.appMessages.promotions.visualSearch.title',
          text: 'components.appMessages.promotions.visualSearch.text',
          icon: 'visuallySimilar',
          link: {
            type: 'button',
            text: 'components.appMessages.promotions.visualSearch.action',
            href: { source: 'urlAssembler', key: 'artList', options: { search: { v: '' } } },
            external: false,
          },
        },
      },
      conditions: [{ 'query:v:nexists': null }],
    },
    {
      code: 'wishlist/card/returns',
      content: null,
      link: null,
      location: 'wishlist',
      priority: 30,
      options: {
        dismiss: false,
        listPosition: { mobile: 10, tablet: 12, desktop: 12 },
        contentType: 'component',
        componentName: 'PromotionGridCard',
        componentProps: {
          title: 'components.appMessages.promotions.returns.title',
          text: null,
          icon: 'freeReturn',
          link: {
            type: 'button',
            text: 'common.learnMore',
            href: { source: 'config', key: 'externalUrls.freeReturns' },
            target: '_blank',
            external: true,
          },
        },
      },
      conditions: [],
    },
    {
      code: 'wishlist/card/advisors',
      content: null,
      link: null,
      location: 'wishlist',
      priority: 30,
      options: {
        dismiss: false,
        listPosition: { mobile: 15, tablet: 15, desktop: 18 },
        contentType: 'component',
        componentName: 'PromotionGridCard',
        componentProps: {
          title: 'components.appMessages.promotions.advisors.title',
          text: null,
          icon: 'customer',
          link: {
            type: 'button',
            text: 'components.appMessages.promotions.advisors.action',
            href: { source: 'config', key: 'externalUrls.talkToCurator' },
            target: '_blank',
            external: true,
          },
        },
      },
      conditions: [],
    },
    {
      code: 'wishlist/card/register',
      content: null,
      link: null,
      location: 'wishlist',
      priority: 30,
      options: {
        dismiss: false,
        listPosition: 2,
        contentType: 'component',
        componentName: 'PromotionGridCard',
        componentProps: {
          title: 'components.appMessages.promotions.saveWishlist.title',
          text: 'components.appMessages.promotions.saveWishlist.text',
          icon: 'saveWishlist',
          link: {
            type: 'button',
            text: 'components.appMessages.promotions.saveWishlist.action',
            href: { source: 'urlAssembler', key: 'register', options: {} },
            external: false,
          },
        },
      },
      conditions: [{ 'customData:auth:aclRole:eq': 'visitor' }],
    },
  ],
};
export const locales = {
  'en-GB': {
    externalUrls: {
      talkToCurator: '/talk-to-curator',
      framingHelp: 'https://help.riseart.com/category/169-framing',
      rentHelp: 'https://help.riseart.com/article/11-how-does-renting-art-work',
      instalmentsHelp: 'https://help.riseart.com/article/385-pay-in-instalments-and-pay-later',
      customsChargesHelp: 'https://help.riseart.com/article/196-customs',
      shippingHelp: 'https://help.riseart.com/category/167-shipping',
      returnsHelp: 'https://help.riseart.com/category/168-returns',
      freeReturns: 'https://get.riseart.com/returns/',
      artCommissionsPage: 'https://get.riseart.com/commissions/',
      articleRssFeed: '/rss',
      contact: '/contact',
      giftCard: '/gift',
      privacyPolicy: '/privacy',
      returnPolicy: 'https://help.riseart.com/article/16-returns',
      securePayment: 'https://help.riseart.com/article/202-payment-security',
      companyReviews: 'https://www.reviews.io/company-reviews/store/riseart-com',
      kazoart: '/kazoart',
      kazoartProLeasing: 'https://pro.kazoart.com/en/art-leasing',
      sohoHome: 'https://get.riseart.com/sohohome/',
      sellerConsole: 'https://sellers.dev.riseart.com',
    },
    contact: {
      emails: { advisory: 'advisors@riseart.com', shop: 'shop@riseart.com' },
      advisor: {
        name: 'Asia Pettitt',
        phone: '+44 208 629 1525',
        avatar:
          'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/02/6c4158ee-b97a-478d-b2c2-47b1cd224b5f.jpg',
      },
    },
    components: {
      checkoutAuth: { routeToType: { login: 'login', join: 'join' } },
      cart: { routeToType: { cart: 'cart', buy: 'buy', rent: 'rent' } },
    },
    navigation: {
      uri: {
        query: {
          params: {
            marketing: 'mktg',
            visitor: '_vid',
            message: '_msg',
            forward: 'forward',
            code: 'code',
            page: 'page',
          },
          values: {},
        },
        hash: {
          params: {
            credit: 'credit',
            discount: 'discount',
            giftCard: 'gift-card',
            detail: 'detail',
            similarArtworks: 'similar',
            enquireForm: 'enquire-form',
            filters: 'filters',
            event: 'event',
            rsvp: 'rsvp',
            follow: 'follow',
            offer: 'offer',
          },
          values: {},
        },
      },
    },
  },
  'de-DE': {
    externalUrls: {
      talkToCurator: '/de/kunstberatung',
      framingHelp: 'https://help.riseart.com/category/169-framing',
      rentHelp: 'https://help.riseart.com/article/11-how-does-renting-art-work',
      instalmentsHelp:
        'https://beistand.riseart.com/article/400-in-raten-zahlen-und-spater-bezahlen',
      customsChargesHelp: 'https://beistand.riseart.com/article/236-zoll',
      shippingHelp: 'https://beistand.riseart.com/category/232-versand-und-rucksendung',
      returnsHelp: 'https://beistand.riseart.com/article/237-rucksendung',
      freeReturns: 'https://get.riseart.com/ruecksendungen/',
      artCommissionsPage: 'https://get.riseart.com/de/individuelle-auftragsarbeiten/',
      articleRssFeed: '/de/rss',
      contact: '/de/kontakt',
      giftCard: '/de/geschenkkarten',
      privacyPolicy: '/de/datenschutzerklaerung',
      returnPolicy: 'https://help.riseart.com/article/16-returns',
      securePayment: 'https://help.riseart.com/article/202-payment-security',
      companyReviews: 'https://www.reviews.io/company-reviews/store/riseart-com',
      kazoart: '/de/kazoart',
      kazoartProLeasing: 'https://pro.kazoart.com/en/art-leasing',
      sohoHome: 'https://get.riseart.com/sohohome/',
      sellerConsole: 'https://sellers.dev.riseart.com',
    },
    contact: {
      emails: { advisory: 'advisors@riseart.com', shop: 'shop@riseart.com' },
      advisor: {
        name: 'Asia Pettitt',
        phone: '+44 208 629 1525',
        avatar:
          'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/02/6c4158ee-b97a-478d-b2c2-47b1cd224b5f.jpg',
      },
    },
    components: {
      checkoutAuth: { routeToType: { anmelden: 'login', teilnehmen: 'join' } },
      cart: { routeToType: { warenkorb: 'cart', kaufen: 'buy', mieten: 'rent' } },
    },
    navigation: {
      uri: {
        query: {
          params: {
            marketing: 'mktg',
            visitor: '_vid',
            message: '_msg',
            forward: 'weiterleiten',
            code: 'code',
            page: 'seite',
          },
          values: {},
        },
        hash: {
          params: {
            credit: 'kredit',
            discount: 'rabatt',
            giftCard: 'geschenkkarte',
            detail: 'detail',
            similarArtworks: 'aehnlich',
            enquireForm: 'anfrageformular',
            filters: 'filtert',
            event: 'veranstaltung',
            rsvp: 'wawg',
            follow: 'folgen',
            offer: 'angebot',
          },
          values: {},
        },
      },
    },
  },
  'fr-FR': {
    externalUrls: {
      talkToCurator: '/fr/service-art-advisory',
      framingHelp: 'https://aide.riseart.com/category/273-encadrement',
      rentHelp: 'https://aide.riseart.com/article/308-location-dune-uvre-dart',
      instalmentsHelp:
        'https://aide.riseart.com/article/387-payer-en-plusieurs-versements-et-payer-plus-tard',
      customsChargesHelp: 'https://aide.riseart.com/article/315-douanes',
      shippingHelp: 'https://aide.riseart.com/category/280-livraison',
      returnsHelp: 'https://aide.riseart.com/category/279-retours',
      freeReturns: 'https://get.riseart.com/fr/retours/',
      artCommissionsPage: 'https://get.riseart.com/fr/commandes/',
      articleRssFeed: '/fr/rss',
      contact: '/fr/contactez-nous',
      giftCard: '/fr/cartes-cadeaux',
      privacyPolicy: '/fr/confidentialite',
      returnPolicy: 'https://aide.riseart.com/article/312-notre-politique-de-retour',
      securePayment: 'https://aide.riseart.com/article/306-securite-des-paiements',
      companyReviews: 'https://www.reviews.io/company-reviews/store/riseart-com',
      kazoart: '/fr/kazoart',
      kazoartProLeasing: 'https://pro.kazoart.com/fr/leasing-art',
      sohoHome: 'https://get.riseart.com/fr/sohohome/',
      sellerConsole: 'https://sellers.dev.riseart.com?locale=fr-FR',
    },
    contact: {
      emails: { advisory: 'advisors@riseart.com', shop: 'shop@riseart.com' },
      advisor: {
        name: 'Elsa Gascon',
        phone: '+33 (0)1 86 95 70 64',
        avatar:
          'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/02/0f567998-2d5d-4dc0-97b9-ed0cbfbaac1e.jpg',
      },
    },
    components: {
      checkoutAuth: { routeToType: { connexion: 'login', rejoindre: 'join' } },
      cart: { routeToType: { panier: 'cart', acheter: 'buy', louer: 'rent' } },
    },
    navigation: {
      uri: {
        query: {
          params: {
            marketing: 'mktg',
            visitor: '_vid',
            message: '_msg',
            forward: 'avant',
            code: 'code',
            page: 'page',
          },
          values: {},
        },
        hash: {
          params: {
            credit: 'credit',
            discount: 'reeduction',
            giftCard: 'carte-cadeau',
            detail: 'detail',
            similarArtworks: 'similaire',
            enquireForm: 'formulaire-renseignements',
            filters: 'filtres',
            event: 'evenement',
            rsvp: 'rsvp',
            follow: 'suivre',
            offer: 'offrir',
          },
          values: {},
        },
      },
    },
  },
};
export const environment = 'development';
export const graphql = {
  endpoint: 'https://graphql.dev.riseart.com',
  apiKey: 'da2-czig55ili5h7hedbjpvxz3v5j4',
  authKey: '68f2683b-dc7b-43ef-8bca-2bd2771017d9',
};
export const debug = {
  console: true,
  sentry: false,
  redux: true,
  logStoreState: true,
  traceback: true,
};
export const cdn = {
  fileSystem: { host: 'https://d1g280u56q0141.cloudfront.net', thumbs: 'thumbs' },
};
export const sentry = {
  accountEndpoint: 'https://b1bf84a381344c34bccefb2ec3e15e82@sentry.io/220845',
};
export const quiz = { resourceId: '4' };
export const pages = {
  error: {
    error: {
      defaultBackgroundImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/2017/10/59dcad93c93c0.jpeg',
    },
  },
};
export const version = { number: '74.1.2', hash: 'fea091b' };
