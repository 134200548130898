export const stores = ['uk', 'eu', 'us'];
export const units = {
  system: { metric: 'metric', imperial: 'imperial' },
  centimeter: { short: 'cm', long: 'centimeter' },
  inch: { short: 'in', long: 'inch' },
};
export const nameValue = { type: { STRING: 0, INTEGER: 1, BOOLEAN: 2, FLOAT: 3, TEXT: 4 } };
export const analytics = {
  visualSearch: {
    trigger: {
      ARTWORK_DETAIL: 'artwork_detail',
      ARTWORK_CATEGORY: 'artwork_category',
      WISHLIST: 'wishlist',
    },
  },
  lensSearch: { trigger: { SEARCH_BAR: 'search_bar', SEARCH_RESULTS: 'search_results' } },
  page: { type: { TYPE_PDP: 'pdp', TYPE_PLP: 'plp' } },
  authDomain: { ra: 'riseart', fb: 'facebook', goog: 'google' },
  element: {
    location: { filterArtList: 'filterArtList' },
    action: { addToCart: 'addToCart' },
    object: { art: 'art' },
  },
  location: { art: 'art', cart: 'cart' },
  list: {
    name: {
      RECENT_ART_WIDGET: 'recent_art_widget_slider',
      ARTICLE_RELATED_ART: 'article_related_art_slider',
      ARTWORK_ARTIST_ART: 'artwork_artist_art_slider',
      ARTWORK_SMILIAR_ART: 'artwork_similar_art_slider',
      ARTWORK_RECENT_ART: 'artwork_recent_art_slider',
      CART_WISHLIST_ART: 'cart_wishlist_art_slider',
      QUIZ_RECOMMEND_ART: 'quiz_recommend_art_slider',
      ART_GRID: 'art_grid',
      ARTIST_ART_GRID: 'artist_art_grid',
      COLLECTION_ART_GRID: 'collection_art_grid',
      WISHLIST_ART_GRID: 'wishlist_art_grid',
      ARTIST_SELECTED_ART: 'artist_selected_art_slider',
      CMS_ART_FIXED_HEIGHT_SLIDER: 'cms_art_fixed_height_slider',
      SEARCH_ARTWORKS: 'search_artworks',
    },
  },
};
export const art = {
  list: { LAYOUT_SQUARE: 'sq', LAYOUT_FLUID: 'fl' },
  filter: { show: { ALL: 'all', BUY: 'buy', ARCHIVE: 'archive' } },
  rank: {
    RANKING_DEFAULT: 'default',
    RANKING_RELEVANCE: 'relevance',
    RANKING_ON_THE_RISE: 'ontherise',
    RANKING_LATEST: 'latest',
  },
  category: {
    image: {
      type: {
        TYPE_BANNER_LARGE: 'banner-large',
        TYPE_BANNER_MEDIUM: 'banner-medium',
        TYPE_BANNER_SMALL: 'banner-small',
      },
    },
  },
  image: {
    type: {
      TYPE_MAIN_MASTER: 'main-master',
      TYPE_MAIN_ORIGINAL: 'main-original',
      TYPE_MAIN_SQUARE: 'main-square',
      TYPE_DETAIL_MASTER: 'detail-master',
      TYPE_DETAIL_ORIGINAL: 'detail-original',
      TYPE_DETAIL_SQUARE: 'detail-square',
      TYPE_FLAT_MASTER: 'master',
      TYPE_FLAT_SQUARE: 'square',
    },
  },
  rental: {
    period: {
      PERIOD_DAY: 'day',
      PERIOD_WEEK: 'week',
      PERIOD_2WEEKS: 'fortnight',
      PERIOD_MONTH: 'month',
      PERIOD_YEAR: 'year',
    },
  },
  enquire: {
    trigger: {
      TRIGGER_ENQUIRE_BUTTON: 'artworkEnquireButton',
      TRIGGER_OFFER_BUTTON: 'artworkOfferButton',
      TRIGGER_HELP_LINK: 'artworkHelpLink',
      TRIGGER_MORE_IMAGES: 'artworkMoreImages',
      TRIGGER_ARTWORK_VIEWING: 'artworkViewingLink',
    },
  },
};
export const activity = {
  image: { type: { TYPE_MASTER: 'master', TYPE_SQUARE: 'square' } },
  type: {
    TYPE_CART_ADD: 'cart-add',
    TYPE_PRODUCT_SOLD: 'product-sold',
    TYPE_PRODUCT_RENTAL: 'product-rental',
    TYPE_FAVORITE_PRODUCT: 'favorite-product',
    TYPE_ARTIST_SHORTLISTED: 'artist-shortlisted',
    TYPE_FOLLOW_SHORTLISTED: 'follow-shortlisted',
    TYPE_PRODUCT_AVAILABLE: 'product-available',
    TYPE_EVENT_CREATE: 'event-created',
    TYPE_EVENT_UPDATE: 'event-updated',
    TYPE_EVENT_CANCEL: 'event-canceled',
    TYPE_EVENT_RSVP_YES: 'event-rsvp-yes',
    TYPE_ARTICLE_CREATE: 'article-created',
    TYPE_COLLECTION_CREATE: 'collection-created',
  },
  object: {
    type: {
      TYPE_ART: 'art',
      TYPE_ARTIST: 'artist',
      TYPE_PRODUCT: 'product',
      TYPE_USER_RENTAL: 'user-rental',
      TYPE_COMMENT: 'comment',
      TYPE_USER: 'user',
      TYPE_STATUS_MESSAGE: 'status-message',
      TYPE_ARTICLE: 'article',
      TYPE_VOTE: 'vote',
      TYPE_EVENT: 'event',
      TYPE_EVENT_RSVP: 'event-rsvp',
      TYPE_COLLECTION: 'collection',
    },
  },
  feed: { FEED_TRENDING: 'trending' },
  creator: {
    type: {
      TYPE_ARTIST: 'artist',
      TYPE_USER: 'user',
      TYPE_ADMIN: 'admin',
      TYPE_PARTNER: 'partner',
      TYPE_AUTHOR: 'author',
      TYPE_VISITOR: 'visitor',
    },
  },
  owner: { type: { TYPE_ARTIST: 'artist', TYPE_USER: 'user' } },
};
export const artist = {
  editorials: {
    TYPE_CURATOR_THOUGHT: 'curator_thought',
    TYPE_ARTIST_REASON: 'artist_reason',
    TYPE_ARTIST_QUOTE: 'artist_quote',
  },
  rank: {
    RANKING_ALPHA: 'alpha',
    RANKING_NEWEST: 'latest',
    RANKING_POPULAR: 'popularity',
    RANKING_FEATURED: 'featured',
    RANKING_RELEVANCE: 'relevance',
  },
  image: {
    type: {
      TYPE_BANNER_MASTER: 'banner-master',
      TYPE_ADDITIONAL_MASTER: 'additional-master',
      TYPE_DETAIL_MASTER: 'detail-master',
    },
  },
  art: { rank: { RANKING_ARTIST: 'artist', RANKING_NEWEST: 'latest', RANKING_PRICE: 'price' } },
  feed: {
    type: { TYPE_TEXT: 'text', TYPE_IMAGE: 'image', TYPE_VIDEO: 'video' },
    image: { type: { TYPE_ORIGINAL: 'original', TYPE_SQUARE: 'square' } },
  },
  careerStage: { NEW: 'new', EMERGING: 'emerging', ESTABLISHED: 'established' },
};
export const author = {
  type: { TYPE_ARTIST: 'artist', TYPE_ADMIN: 'admin', TYPE_USER: 'user', TYPE_PARTNER: 'partner' },
};
export const offer = {
  action: { ACCEPT: 'accept', CANCEL: 'cancel' },
  status: { PENDING: 'pending', ACTIVE: 'active', INACTIVE: 'inactive' },
};
export const filter = {
  domain: {
    ART: 'art',
    ARTIST: 'artist',
    ARTIST_ART: 'artistArt',
    COLLECTION: 'collection',
    COLLECTION_ART: 'collectionArt',
    FAVORITES_ART: 'favoritesArt',
    FAVORITES_ARTIST: 'favoritesArtist',
  },
};
export const mailingList = {
  status: { STATUS_INACTIVE: 0, STATUS_ACTIVE: 1 },
  type: { TYPE_NEWSLETTER: 'newsletter' },
};
export const visitor = {
  role: {
    ROLE_VISITOR: 'visitor',
    ROLE_ARTIST: 'artist',
    ROLE_USER: 'user',
    ROLE_CURATOR: 'curator',
    ROLE_SUBSCRIBER: 'subscriber',
  },
};
export const acl = { role: { ROLE_VISITOR: 'visitor' } };
export const user = {
  account: { status: { SUSPENDED: 2 }, domain: { RISEART: 'ra', FACEBOOK: 'fb', GOOGLE: 'goog' } },
  privacySetting: {
    category: {
      CATEGORY_PRIVACY: 'privacy',
      CATEGORY_EMAIL: 'email',
      CATEGORY_FACEBOOK: 'facebook',
    },
    name: {
      NAME_PRIVACY_SHOW_PERSONAL_DATA: 'show_personal_data',
      NAME_EMAIL_GENERAL: 'general',
      NAME_EMAIL_ACTIVITY_UPDATE: 'activity_update',
      NAME_EMAIL_ACTIVITY_WEEKLY: 'activity_weekly',
      NAME_FACEBOOK_ACTION_FOLLOW: 'action_follows',
      NAME_FACEBOOK_ACTION_FAVORITE: 'action_favorite',
      NAME_FACEBOOK_ACTION_VOTE: 'action_vote',
      NAME_FACEBOOK_ACTION_TAKE: 'action_take',
    },
  },
  role: { ROLE_ARTIST: 'artist' },
};
export const product = {
  type: {
    TYPE_LIMITED_EDITION: 'LE',
    TYPE_ORIGINAL: 'OR',
    TYPE_ARTIST_PROOF: 'AP',
    TYPE_GALLERY_EDITION: 'GE',
  },
  status: {
    STATUS_INACTIVE: 0,
    STATUS_ACTIVE: 1,
    STATUS_SOLD_OUT: 2,
    STATUS_DELETED: 3,
    STATUS_RENTED: 4,
    STATUS_PENDING_APPROVAL: 5,
    STATUS_UNLISTED: 6,
    STATUS_ENQUIRE: 7,
  },
  image: {
    type: {
      TYPE_ORIGINAL_MASTER: 'original-master',
      TYPE_ORIGINAL_DETAIL: 'original-detail',
      TYPE_ORIGINAL_SQUARE: 'original-square',
    },
  },
};
export const quiz = {
  question: {
    type: {
      TYPE_TEXT: 'text',
      TYPE_RADIO: 'radio',
      TYPE_IMAGE: 'image',
      TYPE_CURRENCY: 'currency',
      TYPE_CHECKBOX: 'checkbox',
    },
    dataType: {
      DATA_TYPE_STYLE: 'style',
      DATA_TYPE_DEMO_GENDER: 'demo-gender',
      DATA_TYPE_DEMO_NAME: 'demo-name',
      DATA_TYPE_DEMO_COUNTRY: 'demo-country',
      DATA_TYPE_DEMO_AGE: 'demo-age',
      DATA_TYPE_DEMO_SPEND: 'demo-spend',
      DATA_TYPE_USER_ROLE: 'user-type',
      DATA_TYPE_PROFILE_MEDIUM: 'profile-medium',
      DATA_TYPE_PROFILE_SUBJECT: 'profile-subject',
      DATA_TYPE_PERSONAL_CURATE: 'personal-curate',
      DATA_TYPE_OTHER: 'other',
    },
  },
  profile: {
    relation: { image: { type: { TYPE_ORIGINAL: 'original' } } },
    image: { type: { TYPE_ORIGINAL: 'original', TYPE_SHARE: 'share' } },
  },
};
export const cart = {
  type: { TYPE_BUY: 'buy', TYPE_RENT: 'rent' },
  payment: {
    method: {
      METHOD_DATACASH: 'datacash',
      METHOD_STRIPE: 'riseart_stripe',
      METHOD_CREDIT: 'customercredit',
      METHOD_FREE: 'free',
    },
    stripe: {
      mode: { payment: 'payment' },
      payment: { method: { creation: { manual: 'manual' } }, type: { rent: { CARD: 'card' } } },
    },
  },
  itemAction: {
    action: {
      ACTION_ADD: 'add',
      ACTION_REMOVE: 'remove',
      ACTION_UPDATE: 'update',
      ACTION_DELETE: 'delete',
    },
  },
  sidebar: {
    trigger: {
      TRIGGER_SECONDARY_MENU: 'secondaryMenu',
      TRIGGER_SIDEBAR: 'sidebar',
      TRIGGER_ADD_CART_BTN: 'addToCartButton',
    },
  },
  cardType: {
    CARD_TYPE_AMEX: 'amex',
    CARD_TYPE_MASTERCARD: 'master',
    CARD_TYPE_MASTERCARD_DEBIT: 'master_debit',
    CARD_TYPE_MAESTRO: 'maestro',
    CARD_TYPE_VISA: 'visa',
    CARD_TYPE_VISA_DEBIT: 'visa_debit',
    CARD_TYPE_VISA_ELECTRON: 'visa_electron',
    CARD_TYPE_DINERS: 'diners',
    CARD_TYPE_DISCOVER: 'discover',
    CARD_TYPE_JCB: 'jcb',
    CARD_TYPE_UNIONPAY: 'unionpay',
    CARD_TYPE_UNKNOWN: 'unknown',
  },
  paymentType: { TYPE_CARD: 'card', TYPE_PAYPAL: 'paypal', TYPE_KLARNA: 'klarna' },
};
export const order = {
  state: { STATE_CANCELED: 'canceled', STATE_PAYMENT_REVIEW: 'payment_review' },
};
export const event = {
  type: { TYPE_SOLO_SHOW: 1, TYPE_ART_FAIR: 3, TYPE_TALK: 4, TYPE_GROUP_SHOW: 5, TYPE_OTHER: 6 },
  image: { type: { TYPE_ORIGINAL: 'original', TYPE_MASTER: 'master', TYPE_SQUARE: 'square' } },
  rsvp: { status: { RSVP_NO: 0, RSVP_YES: 1, RSVP_MAYBE: 2 } },
};
export const article = {
  type: { TYPE_POST: 'post', TYPE_GUIDE: 'guide' },
  tag: { MOST_READ: 'most_read', CURATOR_PICK: 'curator_pick' },
  image: { type: { TYPE_MAIN_MASTER: 'main-master', TYPE_BANNER_MASTER: 'banner-master' } },
};
export const avatar = { image: { type: { TYPE_SQUARE: 'square' } } };
export const auth = {
  modules: {
    WEBSITE_USER: 'website/user',
    WEBSITE_VISITOR: 'website/visitor',
    WEBSITE_OAUTH: 'website/oauth',
    WEBSITE_LINK: 'website/link',
    WEBSITE_REFRESH: 'website/refresh',
  },
};
export const legal = {
  type: {
    LEGAL_TYPE_TERMS: 'website_terms',
    LEGAL_TYPE_PRIVACY: 'privacy_policy',
    LEGAL_TYPE_RENTAL: 'rental',
  },
};
export const marketing = {
  scope: { SCOPE_GLOBAL: 'global', SCOPE_STORE: 'store' },
  status: { STATUS_ACTIVE: 1 },
  setting: {
    name: {
      NAME_AFFILIATE_PERSIST: 'affiliate_persist',
      NAME_AFFILIATE_LIFETIME: 'affiliate_lifetime',
      NAME_PAGE_URI: 'page_uri',
    },
  },
};
export const errors = {
  handlers: { SILENT: 'silent', ERROR_PAGE: 'errorPage', NOTIFICATION: 'notification' },
  levels: { ERROR: 10, WARNING: 20, INFO: 30, SUCCESS: 40 },
};
export const pagination = { type: { INFINITE: 'infinite', SLIDER: 'slider' } };
export const meta = {
  TAGNAME: { TITLE: 'title', META: 'meta', LINK: 'link', SCRIPT: 'script' },
  METATYPE: {
    PAGE_TITLE: 'pageTitle',
    META_TITLE: 'metaTitle',
    META_KEYWORDS: 'metaKeywords',
    META_DESCRIPTION: 'metaDescription',
    META_STATUS: 'metaStatus',
    META_ROBOTS: 'metaRobots',
    META_OG_TITLE: 'ogTitle',
    META_OG_DESCRIPTION: 'ogDescription',
    META_OG_IMAGE: 'ogImage',
    META_OG_URL: 'ogUrl',
    META_OG_TYPE: 'ogType',
    META_OG_PROFILE_FIRST_NAME: 'ogProfileFirstName',
    META_OG_PROFILE_LAST_NAME: 'ogProfileLastName',
    META_OG_PROFILE_GENDER: 'ogProfileGender',
    META_TWITTER_CARD: 'twitterCard',
    META_TWITTER_URL: 'twitterUrl',
    META_TWITTER_TITLE: 'twitterTitle',
    META_TWITTER_DESCRIPTION: 'twitterDescription',
    META_TWITTER_IMAGE: 'twitterImage',
    LINK_NEXT: 'linkNext',
    LINK_PREV: 'linkPrev',
    LINK_CANONICAL: 'linkCanonical',
    LINK_CANONICAL_REDIRECT: 'linkCanonicalRedirect',
    LINK_HREFLANG: 'linkHreflang',
    LINK_HREFLANG_REDIRECT: 'linkHreflangRedirect',
    SCRIPT_LD_JSON_WEBPAGE: 'structuredDataWebpage',
    SCRIPT_LD_JSON_BREADCRUMBS: 'structuredDataBreadcrumbs',
    SCRIPT_LD_JSON_PRODUCT: 'structuredDataProduct',
    SCRIPT_LD_JSON_ARTWORK: 'structuredDataArtwork',
    SCRIPT_LD_JSON_ARTWORKS_LIST: 'structuredDataArtworksList',
    SCRIPT_LD_JSON_ARTWORKS_ARTIST: 'structuredDataArtworksArtist',
    SCRIPT_LD_JSON_ARTWORKS_SIMILAR: 'structuredDataArtworksSimilar',
    SCRIPT_LD_JSON_ARTWORKS_RECOMMENDED: 'structuredDataArtworksRecommended',
    SCRIPT_LD_JSON_COLLECTION: 'structuredDataCollection',
    SCRIPT_LD_JSON_PERSON: 'structuredDataPerson',
    SCRIPT_LD_JSON_BLOG: 'structuredDataBlog',
    SCRIPT_LD_JSON_ARTICLE: 'structuredDataArticle',
    SCRIPT_LD_JSON_ARTICLES_LIST: 'structuredDataArticlesList',
    SCRIPT_LD_JSON_ARTICLE_CATEGORIES_LIST: 'structuredDataArticleCategoriesList',
    SCRIPT_LD_JSON_ARTISTS_LIST: 'structuredDataArtistsList',
    SCRIPT_LD_JSON_ARTIST: 'structuredDataArtist',
    SCRIPT_LD_JSON_AGGREGATE_RATING: 'structuredDataAggregateRating',
  },
  ATTRIBUTE_VALUE: {
    KEYWORDS: 'keywords',
    DESCRIPTION: 'description',
    NEXT: 'next',
    PREV: 'prev',
    CANONICAL: 'canonical',
    ALTERNATE: 'alternate',
    OG_TITLE: 'og:title',
    OG_DESCRIPTION: 'og:description',
    OG_IMAGE: 'og:image',
    OG_URL: 'og:url',
    OG_TYPE: 'og:type',
    OG_PROFILE_FIRST_NAME: 'profile:first_name',
    OG_PROFILE_LAST_NAME: 'profile:last_name',
    OG_PROFILE_GENDER: 'profile:gender',
    TWITTER_CARD: 'twitter:card',
    TWITTER_URL: 'twitter:url',
    TWITTER_TITLE: 'twitter:title',
    TWITTER_DESCRIPTION: 'twitter:description',
    TWITTER_IMAGE: 'twitter:image',
    ROBOTS: 'robots',
    STATUS: 'status',
    APPLICATION_LDJSON: 'application/ld+json',
  },
  ATTRIBUTE_NAME: {
    PROPERTY: 'property',
    NAME: 'name',
    CONTENT: 'content',
    HTTP_EQUIV: 'http-equiv',
  },
  ATTRIBUTE_CONTENT: { NO_INDEX: 'noindex', NO_FOLLOW: 'nofollow' },
  SUBSCRIBER_NAME: {
    ROUTER_META: 'routerMeta',
    PAGE_META: 'pageMeta',
    FILTER_CATEGORY_META: 'filterCategoryMeta',
    ART_META: 'artMeta',
    ARTIST_META: 'artistMeta',
    COLLECTION_META: 'collectionMeta',
    COLLECTION_LOCALE_META: 'collectionLocaleMeta',
    FILTER_PAGE_ROUTE_META: 'filterPageRouteMeta',
    QUIZ_PROFILE_META: 'quizProfileMeta',
    PAGINATION_META: 'paginationMeta',
    BLOG_HOME_META: 'blogHomeMeta',
    BLOG_CATEGORY_META: 'blogCategoryMeta',
    BLOG_ARTICLE_META: 'blogArticleMeta',
    GUIDE_HOME_META: 'guideHomeMeta',
    GUIDE_CATEGORY_META: 'guideCategoryMeta',
    GUIDE_ARTICLE_META: 'guideArticleMeta',
    CMS_META: 'cmsMeta',
    ERROR_META: 'errorMeta',
    EVENT_META: 'eventMeta',
  },
  MERGE_CONDITION: { MERGE: 'merge', CONCAT: 'concat' },
};
export const collection = {
  image: {
    type: {
      TYPE_MAIN_ORIGINAL: 'main-original',
      TYPE_MAIN_MASTER: 'main-master',
      TYPE_MAIN_SQUARE: 'main-square',
      TYPE_BANNER_ORIGINAL: 'banner-original',
      TYPE_BANNER_LARGE: 'banner-large',
      TYPE_BANNER_MEDIUM: 'banner-medium',
      TYPE_BANNER_SMALL: 'banner-small',
    },
  },
};
export const share = {
  sources: {
    facebook: { key: 'facebook', medium: 'social' },
    twitter: { key: 'twitter', medium: 'social' },
    email: { key: 'email', medium: 'email' },
    whatsapp: { key: 'whatsapp', medium: 'social' },
    pinterest: { key: 'pinterest', medium: 'social' },
  },
  objects: {
    artwork_detail: { category: 'art', campaign: 'share_artwork_detail' },
    collection_detail: { category: 'collection', campaign: 'share_collection_detail' },
    quiz_personality: { category: 'quiz', campaign: 'share_quiz_personality' },
    artistProfile: { category: 'artist', campaign: 'share_artist_profile' },
    article: { category: 'article', campaign: 'share_article_detail' },
    guide: { category: 'guide', campaign: 'share_guide_detail' },
    event: { category: 'event', event: 'share_event_detail' },
  },
};
export const HTTP_STATUS = {
  MOVED_PERMANENTLY: 301,
  MOVED_TEMPORARILY: 302,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
};
export const email = {
  type: { ARTWORK_ENQUIRE: 'artwork_enquire', ARTWORK_OFFER: 'artwork_offer' },
  object: { type: { ART: 'art' } },
  validation: { type: { FORGOT_PASSWORD: 2 } },
};
export const search = { domain: { ALL: 'all', ARTIST: 'artist', ART: 'art' } };
export const file = {
  object: { type: { IMAGE_UPLOAD: 'image-upload' } },
  imageUpload: {
    object: { type: { USER: 'user' } },
    category: { VISUAL_SEARCH_USER: 'visual-search-user' },
    type: {
      TYPE_VISUAL_SEARCH_USER_ORIGINAL: 'visual-search-user-original',
      TYPE_VISUAL_SEARCH_USER_MASTER: 'visual-search-user-master',
      TYPE_VISUAL_SEARCH_USER_SQUARE: 'visual-search-user-square',
    },
  },
};
