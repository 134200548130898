// @flow

import querystring from 'query-string';
import { stringifyHash } from '@riseart/fe-utils';
import { LocalizedConfig } from 'shared_services/riseart/utils/LocalizedConfig';
import { application as CONFIG_APP } from 'Config';
import { article as ENUM_ARTICLE, author as ENUM_AUTHOR, activity as ENUM_ACTIVITY } from 'Enum';
import { assembleUrl } from 'shared_services/riseart/utils/RouteUtils';
import { URL_PARTS, UrlTranslator } from 'shared_services/riseart/url/Translator';

/**
 * UrlAssembler
 */
export const UrlAssembler = {
  locale: null,

  /**
   * config
   *
   * @param {Object} locale
   * @returns {void}
   */
  config(locale: Object): void {
    UrlAssembler.locale = locale;
  },

  /**
   * mergeOptions
   *
   * @param {Object} options
   * @returns {Object}
   */
  mergeOptions(options: Object = {}): Object {
    const { search, hash, locale, ...restOptions } = options;
    const parsedSearch = typeof search === 'string' ? querystring.parse(search) : search;
    const translations = {
      search:
        parsedSearch &&
        querystring.stringify(UrlTranslator.translateTo(parsedSearch, URL_PARTS.QUERY, locale)),
      hash: hash && stringifyHash(UrlTranslator.translateTo(hash, URL_PARTS.HASH, locale)),
    };

    return {
      ...restOptions,
      search: translations.search ? `?${translations.search}` : '',
      hash: translations.hash ? translations.hash : '',
      locale: locale || UrlAssembler.locale,
    };
  },

  /**
   * byRouteKey
   *
   * @param {string} routeKey
   * @param {Object} options
   * @returns {string}
   */
  byRouteKey(routeKey: string, options: ?Object): string {
    switch (routeKey) {
      case 'cart': {
        const { cartType, ...restOptions } = options || {};

        return UrlAssembler.cart(
          cartType || (restOptions && restOptions.params && restOptions.params.cartType),
          restOptions,
        );
      }
      case 'checkout': {
        const { cartType, ...restOptions } = options || {};

        return UrlAssembler.checkout(
          cartType || (restOptions && restOptions.params && restOptions.params.cartType),
          restOptions,
        );
      }
      case 'checkoutAuth': {
        const { authType, ...restOptions } = options || {};

        return UrlAssembler.checkoutAuth(
          authType || (restOptions && restOptions.params && restOptions.params.authType),
          restOptions,
        );
      }
      default: {
        return assembleUrl(routeKey, UrlAssembler.mergeOptions(options));
      }
    }
  },

  /**
   * artistSubpage
   *
   * @param {string} pageKey
   * @param {number | string} id
   * @param {string} alias
   * @param {Object} options
   * @returns {string | null}
   */
  artistSubpage(
    pageKey: string,
    id: number | string,
    alias: string,
    options: Object = {},
  ): string | null {
    return id && alias
      ? assembleUrl(pageKey, { params: { id, alias }, ...UrlAssembler.mergeOptions(options) })
      : null;
  },

  /**
   * artistProfile
   *
   * @param {number | string} id
   * @param {string} alias
   * @param {Object} options
   * @returns {string | null}
   */
  artistProfile(id: number | string, alias: string, options?: Object = {}): string | null {
    return UrlAssembler.artistSubpage('artistProfile', id, alias, options);
  },

  /**
   * artistList
   *
   * @param {Object} options
   * @returns {string}
   */
  artistList(options?: Object = {}): string {
    return assembleUrl('artistList', UrlAssembler.mergeOptions(options));
  },

  /**
   * authorDetail
   *
   * @param {string} type
   * @param {number | string} typeId
   * @param {string} typeAlias
   * @param {Object} options?
   * @returns {?string}
   */
  authorDetail(
    type: string,
    typeId: number | string,
    typeAlias: string,
    options?: Object = {},
  ): ?string {
    switch (type) {
      case ENUM_AUTHOR.type.TYPE_ARTIST:
        return UrlAssembler.artistProfile(typeId, typeAlias, UrlAssembler.mergeOptions(options));
      default:
        return null;
    }
  },

  /**
   * artList
   *
   * @param {Object} options
   * @returns {string}
   */
  artList(options?: Object = {}): string {
    return assembleUrl('artList', UrlAssembler.mergeOptions(options));
  },

  /**
   * artDetail
   *
   * @param {number | string} id
   * @param {string} alias
   * @param {Object} options
   * @returns {?string}
   */
  artDetail(
    id: number | string,
    slug: string,
    { params = null, ...options }: Object = {},
  ): ?string {
    return id && slug
      ? assembleUrl('artDetail', {
          ...(UrlAssembler.mergeOptions(options) || null),
          params: { id, slug, ...params },
        })
      : null;
  },

  /**
   * collectionList
   *
   * @param {Object} options
   * @returns {string}
   */
  collectionList(options?: Object = {}): string {
    return assembleUrl('collectionList', UrlAssembler.mergeOptions(options));
  },

  /**
   * collectionDetail
   *
   * @param {number | string} id
   * @param {string} slug
   * @param {Object} options
   * @returns {?string}
   */
  collectionDetail(
    id: number | string,
    slug: string,
    { params = null, ...options }: Object = {},
  ): ?string {
    return id && slug
      ? assembleUrl('collectionDetail', {
          ...(UrlAssembler.mergeOptions(options) || null),
          params: { id, slug, ...params },
        })
      : null;
  },

  /**
   * eventList
   *
   * @param {Object} options
   * @returns {string}
   */
  eventList(options?: Object = {}): string {
    return assembleUrl('eventList', UrlAssembler.mergeOptions(options));
  },

  /**
   * eventDetail
   *
   * @param {number | string} id
   * @param {string} slug
   * @param {Object} options
   * @returns {?string}
   */
  eventDetail(
    id: number | string,
    slug: string,
    { params = null, ...options }: Object = {},
  ): ?string {
    return id && slug
      ? assembleUrl('eventDetail', {
          ...(UrlAssembler.mergeOptions(options) || null),
          params: { id, slug, ...params },
        })
      : null;
  },

  /**
   * blogList
   *
   * @param {Object} options
   * @returns {string}
   */
  blogList(options?: Object = {}): string {
    return assembleUrl('blog', UrlAssembler.mergeOptions(options));
  },

  /**
   * blogCategory
   *
   * @param {string} uri
   * @param {Object} options
   * @returns {?string}
   */
  blogCategory(uri: string, { params = null, ...options }: Object = {}): ?string {
    return uri
      ? assembleUrl('blogCategory', {
          ...(UrlAssembler.mergeOptions(options) || null),
          params: { category: uri, ...params },
        })
      : null;
  },

  /**
   * blogDetail
   *
   * @param {string | number} id
   * @param {string} slug
   * @param {Object} options
   * @returns {?string}
   */
  blogDetail(
    id: string | number,
    slug: string,
    { params = null, ...options }: Object = {},
  ): ?string {
    return id && slug
      ? assembleUrl('blogArticle', {
          ...(UrlAssembler.mergeOptions(options) || null),
          params: { id, slug, ...params },
        })
      : null;
  },

  /**
   * guideList
   *
   * @param {Object} options
   * @returns {string}
   */
  guideList(options?: Object = {}): string {
    return assembleUrl('guides', UrlAssembler.mergeOptions(options));
  },

  /**
   * guideCategory
   *
   * @param {string} uri
   * @param {Object} options
   * @returns {?string}
   */
  guideCategory(uri: string, { params = null, ...options }: Object = {}): ?string {
    return uri
      ? assembleUrl('guideCategory', {
          ...(UrlAssembler.mergeOptions(options) || null),
          params: { category: uri, ...params },
        })
      : null;
  },

  /**
   * guideDetail
   *
   * @param {string | number} id
   * @param {string} slug
   * @param {Object} options
   * @returns {?string}
   */
  guideDetail(
    id: string | number,
    slug: string,
    { params = null, ...options }: Object = {},
  ): ?string {
    return id && slug
      ? assembleUrl('guideArticle', {
          ...(UrlAssembler.mergeOptions(options) || null),
          params: { id, slug, ...params },
        })
      : null;
  },

  /**
   * articleList
   *
   * @param {string} type
   * @param {Object} options
   * @returns {?string}
   */
  articleList(type: string = ENUM_ARTICLE.type.TYPE_POST, options?: Object = {}): ?string {
    switch (type) {
      case ENUM_ARTICLE.type.TYPE_POST:
        return UrlAssembler.blogList(options);
      case ENUM_ARTICLE.type.TYPE_GUIDE:
        return UrlAssembler.guideList(options);
      default:
        return null;
    }
  },

  /**
   * articleCategory
   *
   * @param {string} uri
   * @param {string} type
   * @param {Object} options
   * @returns {?string}
   */
  articleCategory(
    uri: string,
    type: string = ENUM_ARTICLE.type.TYPE_POST,
    options: Object = {},
  ): ?string {
    switch (type) {
      case ENUM_ARTICLE.type.TYPE_POST:
        return UrlAssembler.blogCategory(uri, options);
      case ENUM_ARTICLE.type.TYPE_GUIDE:
        return UrlAssembler.guideCategory(uri, options);
      default:
        return null;
    }
  },

  /**
   * articleDetail
   *
   * @param {string | number} id
   * @param {string} slug
   * @param {string} type
   * @param {Object} options?
   * @returns {?string}
   */
  articleDetail(
    id: string | number,
    slug: string,
    type: string = ENUM_ARTICLE.type.TYPE_POST,
    options: Object = {},
  ): ?string {
    switch (type) {
      case ENUM_ARTICLE.type.TYPE_POST:
        return UrlAssembler.blogDetail(id, slug, options);
      case ENUM_ARTICLE.type.TYPE_GUIDE:
        return UrlAssembler.guideDetail(id, slug, options);
      default:
        return null;
    }
  },

  /**
   * quizProfile
   *
   * @param {string} slug
   * @param {Object} options?
   * @returns {?string}
   */
  quizProfile(slug: string, { params = null, ...options }: Object = {}): ?string {
    return slug
      ? assembleUrl('quizProfile', {
          ...(UrlAssembler.mergeOptions(options) || null),
          params: { slug, ...params },
        })
      : null;
  },

  /**
   * cart
   *
   * @param {'cart' | 'rent'} type
   * @param {Object} options?
   * @returns {string}
   */
  cart(type: 'cart' | 'rent', options?: Object = {}): string {
    const routeToType = LocalizedConfig.get(
      `components.cart.routeToType`,
      (options && options.locale && options.locale.name) ||
        (UrlAssembler.locale && UrlAssembler.locale.name),
    );
    const cartType = Object.keys(routeToType).reduce((accumulator, key) =>
      routeToType[key] === type ? key : accumulator,
    );
    return assembleUrl('cart', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { cartType },
    });
  },

  /**
   * checkout
   *
   * @param {'buy' | 'rent'} type
   * @param {Object} options?
   * @returns {string}
   */
  checkout(type: 'buy' | 'rent' = 'buy', options?: Object = {}): string {
    const routeToType = LocalizedConfig.get(
      `components.cart.routeToType`,
      (options.locale && options.locale.name) || (UrlAssembler.locale && UrlAssembler.locale.name),
    );
    const cartType = Object.keys(routeToType).reduce((accumulator, key) =>
      routeToType[key] === type ? key : accumulator,
    );
    return assembleUrl('checkout', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { cartType },
    });
  },

  /**
   * checkoutAuth
   *
   * @param {'login' | 'join'} type
   * @param {Object} options?
   * @returns {string}
   */
  checkoutAuth(type: 'login' | 'join' = 'join', options?: Object = {}): string {
    const routeToType = LocalizedConfig.get(
      `components.checkoutAuth.routeToType`,
      (options.locale && options.locale.name) || (UrlAssembler.locale && UrlAssembler.locale.name),
    );
    const authType = Object.keys(routeToType).reduce((accumulator, key) =>
      routeToType[key] === type ? key : accumulator,
    );
    return assembleUrl('checkoutAuth', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { authType },
    });
  },

  /**
   * orderConfirmation
   *
   * @param {string | number} orderId
   * @param {Object} options
   * @returns {string | null}
   */
  orderConfirmation(
    orderId: string | number,
    { params = null, ...options }: Object = {},
  ): string | null {
    return orderId
      ? assembleUrl('orderConfirmation', {
          ...(UrlAssembler.mergeOptions(options) || null),
          params: { orderId, ...params },
        })
      : null;
  },

  /**
   * activityCreator
   *
   * @param {Object} activity
   * @param {?Object} options
   * @returns {?string}
   */
  activityCreator(
    { creatorType, creatorSubType, creatorId, creatorSubId, creatorSlug }: Object,
    options: ?Object = {},
  ): ?string {
    switch (creatorType) {
      case ENUM_ACTIVITY.creator.type.TYPE_ARTIST:
        return UrlAssembler.artistProfile(creatorId, creatorSlug, options);
      case ENUM_ACTIVITY.creator.type.TYPE_AUTHOR:
        return UrlAssembler.authorDetail(creatorSubType, creatorSubId, creatorSlug, options);
      default:
        return null;
    }
  },

  /**
   * activityObject
   *
   * @param {Object} activity
   * @param {?Object} options
   * @returns {?string}
   */
  activityObject(
    { objectType, objectId, objectSubId, objectSlug, objectRole }: Object,
    options: ?Object = {},
  ): ?string {
    switch (objectType) {
      case ENUM_ACTIVITY.object.type.TYPE_ART:
        return UrlAssembler.artDetail(objectId, objectSlug, options);
      case ENUM_ACTIVITY.object.type.TYPE_PRODUCT:
      case ENUM_ACTIVITY.object.type.TYPE_USER_RENTAL:
        return UrlAssembler.artDetail(objectSubId, objectSlug, options);
      case ENUM_ACTIVITY.object.type.TYPE_ARTIST:
        return UrlAssembler.artistProfile(objectId, objectSlug, options);
      case ENUM_ACTIVITY.object.type.TYPE_ARTICLE:
        return UrlAssembler.articleDetail(objectId, objectSlug, objectRole, options);
      case ENUM_ACTIVITY.object.type.TYPE_EVENT:
        return UrlAssembler.eventDetail(objectId, objectSlug, options);
      case ENUM_ACTIVITY.object.type.TYPE_EVENT_RSVP:
        return UrlAssembler.eventDetail(objectSubId, objectSlug, options);
      case ENUM_ACTIVITY.object.type.TYPE_COLLECTION:
        return UrlAssembler.collectionDetail(objectId, objectSlug, options);
      default:
        return null;
    }
  },

  /**
   * activityArt
   *
   * @param {Object} activity
   * @param {Object} ?options
   * @returns {?string}
   */
  activityArt({ objectArtId, objectArtSlug }: Object, options: ?Object = {}): ?string {
    if (objectArtId && objectArtSlug) {
      return UrlAssembler.artDetail(objectArtId, objectArtSlug, options);
    }

    return null;
  },

  /**
   * activityArtist
   *
   * @param {Object} activity
   * @param {Object} ?options
   * @returns {?string}
   */
  activityArtist(
    { objectArtArtistId, objectArtArtistAlias }: Object,
    options: ?Object = {},
  ): ?string {
    if (objectArtArtistId && objectArtArtistAlias) {
      return UrlAssembler.artistProfile(objectArtArtistId, objectArtArtistAlias, options);
    }

    return null;
  },

  /**
   * search
   *
   * @param {Object} ?options
   * @returns {?string}
   */
  search(options: ?Object = {}): ?string {
    return assembleUrl('search', { ...(UrlAssembler.mergeOptions(options) || null) });
  },

  /**
   * activityOwner
   *
   * @param {Object} activity
   * @param {?Object} options
   * @returns {?string}
   */
  activityOwner({ ownerType, ownerId, ownerAlias }: Object, options: Object = {}): ?string {
    switch (ownerType) {
      case ENUM_ACTIVITY.owner.type.TYPE_ARTIST:
        return UrlAssembler.artistProfile(ownerId, ownerAlias, options);
      default:
        return null;
    }
  },
  /**
   * auth
   *
   * @param {Object} roles
   * @param {Object} options
   * @return {string}
   */
  auth({ hasAccount, aclRole, visitorRole }: Object, options: Object = {}): string {
    // Build register url for new visitors
    if (
      hasAccount === false ||
      (CONFIG_APP.acl.nonRegisteredRoles.acl.indexOf(aclRole) > -1 &&
        CONFIG_APP.acl.nonRegisteredRoles.visitor.indexOf(visitorRole) > -1)
    ) {
      return UrlAssembler.byRouteKey('register', options);
    }

    // Build login url if visitor has account
    return UrlAssembler.byRouteKey('login', options);
  },
};
